const ALL_SPECIAL_CHARACTERS = /[!&/\\#,+()$~%.’'":;*?<>{}]/g

export const cleanText = (text, language) => {
  let cleanedText = text
    .toLowerCase()
    .replace(ALL_SPECIAL_CHARACTERS, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()
  if (language === 'ko-KR') {
    cleanedText = cleanedText.replace(' ', '')
  }
  return cleanedText
}

export const isMatch = (a, b, language) => {
  const strippedA = cleanText(a, language)
  const strippedB = cleanText(b, language)
  return strippedA === strippedB
}

export const isEmptyObject = obj => (
  Object.keys(obj).length <= 0
)
