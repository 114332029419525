const getAudioStream = () => {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }
  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = constraints => {
      // First get ahold of the legacy getUserMedia, if present
      navigator.getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia
      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!navigator.getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        )
      }
      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise((resolve, reject) => {
        navigator.getUserMedia(navigator, constraints, resolve, reject)
      })
    }
  }

  const params = { audio: true, video: false }
  return navigator.mediaDevices.getUserMedia(params)
}

export default getAudioStream
