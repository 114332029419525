import React from 'react'
import Confetti from 'react-dom-confetti'
import { makeStyles } from '@material-ui/core/styles'

const commonConfig = {
  angle: 270,
  spread: 180,
  startVelocity: 45,
  perspective: '1000px',
  colors: ['#f00', '#0f0', '#00f']
}

const config = {
  small: {
    ...commonConfig,
    elementCount: 20,
    dragFriction: 0.2,
    duration: 2000,
    stagger: 3,
    width: '8px',
    height: '8px'
  },
  big: {
    ...commonConfig,
    elementCount: 70,
    dragFriction: 0.2,
    duration: 4000,
    stagger: 5,
    width: '10px',
    height: '10px'
  }
}

const useStyles = makeStyles(() => ({
  celebrate: {
    position: 'fixed',
    left: '50%',
    right: '50%',
    zIndex: 1
  }
}))

const Celebration = ({ active, type }) => {
  const classes = useStyles()

  return (
    <div className={classes.celebrate} data-testid={type + ' celebration'}>
      <Confetti active={active} config={config[type]} />
    </div>
  )
}
export default Celebration
