import React, { useState } from 'react'
import { Paper, MenuList, MenuItem, Collapse } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'

const LANGUAGES = Object.freeze({
  English: { 'en-US': 'American', 'en-GB': 'British' },
  French: { 'fr-FR': 'French' },
  Hungarian: { 'hu-HU': 'Hungarian' },
  Korean: { 'ko-KR': 'Korean' },
  Polish: { 'pl-PL': 'Polish' },
  Portuguese: { 'pt-PT': 'Portuguese' },
  Russian: { 'ru-RU': 'Russian' },
  Spanish: { 'es-ES': 'Spanish' }
})

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: '11.25rem',
    margin: '0 auto',
    marginTop: '1rem'
  },
  menuList: {
    paddingBottom: 'unset'
  },
  menuItem: {
    minWidth: '11.25rem'
  },
  listItemIcon: {
    minWidth: 'unset'
  },
  childMenuItem: {
    minWidth: '11.25rem',
    paddingLeft: '2rem'
  },
  nestedMenuItem: {
    minWidth: '11.25rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  nestedMenuList: {
    backgroundColor: theme.palette.grey[100],
    paddingBottom: 'unset'
  }
}))

const NestedMenu = ({ accents, title, language, setLanguage, setIsOpen }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClick = languageCode => {
    if (languageCode) {
      setLanguage(languageCode)
      setIsOpen(false)
    }
  }
  return (
    <>
      <MenuItem
        className={classes.nestedMenuItem}
        onClick={() => setOpen(!open)}
        aria-label='nested language selector'
      >
        {title}
        <ListItemIcon className={classes.listItemIcon}>
          {open ? (
            <ExpandLessIcon aria-label='expand less' />
          ) : (
            <ExpandMoreIcon aria-label='expand more' />
          )}
        </ListItemIcon>
      </MenuItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <MenuList className={classes.nestedMenuList}>
          {Object.keys(accents).map(languageCode => (
            <div key={languageCode}>
              <MenuItem
                value={languageCode}
                selected={languageCode === language}
                onClick={() => handleClick(languageCode)}
                className={classes.childMenuItem}
              >
                {accents[languageCode]}
              </MenuItem>
            </div>
          ))}
        </MenuList>
      </Collapse>
    </>
  )
}

const hasChildren = item => Object.keys(item).length > 1

const LanguageSelector = ({ language, setLanguage, isOpen, setIsOpen }) => {
  const classes = useStyles()

  const handleClick = languageCode => {
    if (languageCode) {
      setLanguage(getLanguageCode(languageCode))
      setIsOpen(false)
    }
  }

  const getLanguageCode = language => Object.keys(LANGUAGES[language])[0]

  return (
    <>
      <Paper className={classes.paper}>
        <MenuList
          id='language-menu'
          open={isOpen}
          onClose={handleClick}
          aria-label='language selector'
          value={language}
          className={classes.menuList}
        >
          {Object.keys(LANGUAGES).map(languageTitle => (
            <div key={languageTitle}>
              {!hasChildren(LANGUAGES[languageTitle]) && (
                <MenuItem
                  value={getLanguageCode(languageTitle)}
                  selected={getLanguageCode(languageTitle) === language}
                  onClick={() => handleClick(languageTitle)}
                  className={classes.menuItem}
                >
                  {languageTitle}
                </MenuItem>
              )}
              {hasChildren(LANGUAGES[languageTitle]) && (
                <NestedMenu
                  accents={LANGUAGES[languageTitle]}
                  title={languageTitle}
                  language={language}
                  setLanguage={setLanguage}
                  setIsOpen={setIsOpen}
                />
              )}
            </div>
          ))}
        </MenuList>
      </Paper>
    </>
  )
}

export default LanguageSelector
