import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Tooltip } from '@material-ui/core'

const SuccessCounter = ({ value, size, toolTipPlacement, bgColor }) => (
  <div aria-label='success-counter'>
    {value > 0 && (
      <Box
        position='relative'
        display='inline-flex'
        style={{ verticalAlign: 'middle' }}
      >
        <CircularProgress
          variant='determinate'
          value={value * 33 + 1}
          style={{
            width: size,
            height: size,
            borderRadius: '100%',
            backgroundColor: bgColor
          }}
          thickness={4}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Tooltip
            title={`You pronounced this phrase correctly ${value} times`}
            placement={toolTipPlacement}
            aria-label='practice-phrase-counter-tooltips'
          >
            <Typography
              variant='caption'
              component='div'
              color='textSecondary'
              style={{ fontSize: '20px', fontWeight: 'bold' }}
            >
              {value}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    )}
  </div>
)

export default SuccessCounter
