import React from 'react'
import { Menu, MenuItem, ListItemIcon, Divider, Icon } from '@material-ui/core'
import classNames from 'classnames'

const Logout = ({
  anchorEl,
  setAnchorEl,
  setIsSignedIn,
  setJwtAccessToken,
  loggedInUserEmail,
  setLoggedInUserEmail
}) => {
  const open = Boolean(anchorEl)

  const removeUserFromLocalStore = () => {
    setJwtAccessToken('')
    localStorage.removeItem('user')
  }

  const logout = () => {
    closeModal()
    setLoggedInUserEmail('')
    setIsSignedIn(false)
    removeUserFromLocalStore()
  }

  const closeModal = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        style={{ top: 40 }}
        onClose={closeModal}
      >
        <MenuItem disabled>{loggedInUserEmail}</MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Icon className={classNames('fas fa-sign-out-alt')} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default Logout
