import React, { useContext } from 'react'
import Recorder from 'recorder-js'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import MicRoundedIcon from '@material-ui/icons/MicRounded'
import { RecordingContext } from '../../../context/recordingContext/recordingContext'
import getAudioStream from '../../audioPermission/audioStream'

const useStyles = makeStyles(() => ({
  largeIconMic: {
    margin: '2px 2px 2px 0px'
  },
  practiceButton: {
    padding: '6px 10px 6px 6px'
  }
}))

const StartRecordingButton = () => {
  const classes = useStyles()
  const {
    practicePhrase,
    setIsRecording,
    setTranscription,
    setMatchConfidence,
    setBlobAudio,
    setStartTime,
    stream,
    setStream,
    audioContext,
    setAudioContext,
    setRecorder,
    setAccentConfidence,
    setUrl,
    setAnalyserData
  } = useContext(RecordingContext)

  const startRecording = async () => {
    let clonedAudioContext = audioContext
    if (clonedAudioContext && clonedAudioContext.state === 'suspended') {
      clonedAudioContext.resume()
      setAudioContext(clonedAudioContext)
    } else {
      clonedAudioContext = new (window.AudioContext ||
        window.webkitAudioContext)()
      setAudioContext(clonedAudioContext)
    }
    const recorder = new Recorder(clonedAudioContext, {
      numChannels: 1,
      onAnalysed: data => setAnalyserData(data)
    })
    let clonedStream = stream
    if (clonedStream) {
      clonedStream.getTracks().forEach((track) => { track.enabled = true })
    } else {
      clonedStream = await getAudioStream()
    }
    recorder.init(clonedStream)
    setStream(clonedStream)
    setTranscription('')
    setMatchConfidence(0)
    setBlobAudio(null)
    setAccentConfidence(0)
    setUrl('')
    recorder.start()
    setRecorder(recorder)
    setIsRecording(true)
    setStartTime(Date.now())
  }

  return (
    <>
      <Button
        aria-label='practice-button'
        variant='contained'
        onClick={startRecording}
        color='primary'
        disabled={practicePhrase === ''}
        className={classes.practiceButton}
      >
        <MicRoundedIcon className={classes.largeIconMic} />
        <Typography style={{ fontSize: '15px' }}>Practice</Typography>
      </Button>
    </>
  )
}

export default StartRecordingButton
