import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Slide,
  Icon
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const FLAG_CODES = Object.freeze({
  'en-US': 'US',
  'en-GB': 'GB',
  'fr-FR': 'FR',
  'ko-KR': 'KR',
  'es-ES': 'ES',
  'pt-PT': 'PT',
  'ru-RU': 'RU',
  'hu-HU': 'HU',
  'pl-PL': 'PL'
})

const useStyles = makeStyles(theme => ({
  toolbar: {
    maxWidth: '43rem',
    margin: '0 auto',
    width: '90%',
    justifyContent: 'space-between'
  },
  title: {
    flexGrow: 1,
    fontSize: 24
  },
  whiteBorder: {
    borderWidth: '1px',
    borderColor: theme.palette.info.contrastText,
    borderStyle: 'solid',
    display: 'inherit'
  },
  blackBorder: {
    borderWidth: '1px',
    borderColor: theme.palette.text.primary,
    borderStyle: 'solid',
    display: 'inherit'
  }
}))

const HeaderBar = ({
  language,
  isChoosingLanguage,
  setIsChoosingLanguage,
  setLanguageSelectVisible,
  isSignedIn,
  setIsOpenLoginDialog,
  setLogoutModalPosition
}) => {
  const classes = useStyles()

  const toggleLanguageSelector = () => {
    setIsChoosingLanguage(!isChoosingLanguage)
  }

  const handleLanguageSelector = () => {
    setLanguageSelectVisible(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <AppBar position='fixed' aria-label='header-bar'>
      <Toolbar className={classes.toolbar} variant='dense'>
        {!isSignedIn ? (
          <IconButton
            aria-label='login'
            onClick={() => setIsOpenLoginDialog(true)}
          >
            <Icon className={classNames('fas fa-sign-in-alt')} />
          </IconButton>
        ) : (
          <IconButton
            aria-label='user profile'
            onClick={event => setLogoutModalPosition(event.currentTarget)}
          >
            <Icon className={classNames('fas fa-user-circle')} />
          </IconButton>
        )}
        <Typography variant='h4' className={classes.title}>
          Pronunciation Trainer
        </Typography>
        {language && (
          <Slide
            in={!isChoosingLanguage}
            direction='down'
            style={!isChoosingLanguage ? { transitionDelay: 300 } : {}}
            timeout={{ enter: 250, exit: 200 }}
            onExited={handleLanguageSelector}
          >
            <IconButton
              edge='end'
              color='inherit'
              aria-label='flag-icon'
              onClick={toggleLanguageSelector}
            >
              <div className={classes.whiteBorder}>
                <ReactCountryFlag
                  className={classes.blackBorder}
                  aria-label={FLAG_CODES[language]}
                  countryCode={FLAG_CODES[language]}
                  svg
                  style={{
                    height: 'auto',
                    width: '26.68px'
                  }}
                />
              </div>
            </IconButton>
          </Slide>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default HeaderBar
