import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import LogRocket from 'logrocket'
import update from 'immutability-helper'
import DiffMatchPatch from 'diff-match-patch'
import { v4 as uuidv4 } from 'uuid'
import { loadCSS } from 'fg-loadcss/src/loadCSS'
import Login from './components/login/login'
import HeaderBar from './components/headerBar/headerBar'
import Celebration from './components/celebration/celebration'
import PhraseList from './components/phraseList/phraseList'
import LanguageSelector from './components/languageSelector/languageSelector'
import PracticeCard from './components/practiceCard/practiceCard'
import PracticeHintCard from './components/practiceHintCard/practiceHintCard'
import AlertSnackbar from './components/alertSnackbar/alertSnackbar'
import FeedbackCard from './components/feedbackCard/feedbackCard'
import NativeAudioPlayer from './components/nativeAudioPlayer/nativeAudioPlayer'
import { RecordingContextProvider } from './context/recordingContext/recordingContext'
import { cleanText, isEmptyObject } from './helpers'
import { Container, Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import sharedStyles from './sharedStyles'
import Logout from './components/logout/logout'
import AudioPermission from './components/audioPermission/audioPermission'
import { getUserDataFromDB, getAllSuccessCounts, getPrompts, getCustomLists } from './dbhelper'

require('dotenv').config()

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
  testMode: process.env.GA_TEST_MODE !== 'FALSE'
})

if (process.env.REACT_APP_LOGROCKET_ENABLED === 'TRUE') {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID)
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    overflowX: 'hidden',
    minHeight: '100vh',
    marginTop: '2.5rem'
  },
  phraseList: {
    maxWidth: '100%',
    width: 'fit-content',
    display: 'inline-block'
  }
}))

const PHRASE_PART_TYPES = Object.freeze({
  [-1]: 'extra',
  0: 'correct',
  1: 'mispronounced'
})
const NULL_SUCCESS_COUNTS = { value: {}, expiry: Infinity }

export const getEvaluatedPhraseParts = (
  cleanTranscription,
  cleanPracticePhrase
) => {
  const dmp = new DiffMatchPatch()
  const diffsArray = dmp.diff_main(cleanTranscription, cleanPracticePhrase)
  dmp.diff_cleanupSemantic(diffsArray)

  return diffsArray.map(([difference, substring]) => ({
    type: PHRASE_PART_TYPES[difference],
    text: substring.trim()
  }))
}

const getSuccessCounts = (language, allSuccessCounts) => {
  const successCounts = allSuccessCounts[language] || NULL_SUCCESS_COUNTS
  const now = new Date()

  if (now.getTime() > successCounts.expiry) {
    return NULL_SUCCESS_COUNTS.value
  }

  return successCounts.value
}

const setPracticePhraseInLocalStore = (
  language,
  selectedListName,
  practicePhrase
) => {
  const item = {
    [language]: { [selectedListName]: practicePhrase }
  }
  localStorage.setItem('practicePhrase', JSON.stringify(item))
}

export const getPracticePhraseFromLocalStore = (language, selectedListName) => {
  const practicePhraseStr = localStorage.getItem('practicePhrase')
  const lastChosenPhrase = JSON.parse(practicePhraseStr) || {}
  const phrase = lastChosenPhrase[language] || {}
  return phrase[selectedListName] || ''
}

const getTokenFromLocalStore = () => {
  const user = JSON.parse(localStorage.getItem('user')) || { accessToken: '' }
  return user.accessToken
}

const getUserEmailFromLocalStore = () => {
  const user = JSON.parse(localStorage.getItem('user')) || { email: '' }
  return user.email
}

const App = props => {
  const [transcription, setTranscription] = useState('')
  const lastChosenLanguage = localStorage.getItem('language')
  const [language, _setLanguage] = useState(lastChosenLanguage)
  const [jwtAccessToken, setJwtAccessToken] = useState(getTokenFromLocalStore())
  const [isSignedIn, setIsSignedIn] = useState(Boolean(jwtAccessToken))
  const [matchConfidence, setMatchConfidence] = useState(0)
  const [stream, setStream] = useState(null)
  const [selectedListName, setSelectedListName] = useState(
    localStorage.getItem('selectedListName') || ''
  )
  const [practicePhrase, setPracticePhrase] = useState(
    getPracticePhraseFromLocalStore(language, selectedListName)
  )
  const [startTime, setStartTime] = useState('')
  const [isSpeaking, setIsSpeaking] = useState(props.isSpeaking || false)
  const [isRecording, setIsRecording] = useState(false)
  const [isChoosingLanguage, setIsChoosingLanguage] = useState(!language)
  const [isFeedbackShown, setIsFeedbackShown] = useState(false)
  const [audioContext, setAudioContext] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [blobAudio, setBlobAudio] = useState(null)
  const [url, setUrl] = useState('')
  const [pauseTime, setPauseTime] = useState(5)
  const [successCount, setSuccessCount] = useState(0)
  const [languageSelectVisible, setLanguageSelectVisible] = useState(!language)
  const [accentConfidence, setAccentConfidence] = useState(0)
  const [voiceName, setVoiceName] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [isPhraseCompleted, setIsPhraseCompleted] = useState(false)
  const [isListCompleted, setIsListCompleted] = useState(false)
  const [feedbackCollection, setFeedbackCollection] = useState([])
  const [evaluatedPhraseParts, setEvaluatedPhraseParts] = useState([])
  const [mismatchCount, setMismatchCount] = useState(0)
  const [isPlayingUserAudio, setIsPlayingUserAudio] = useState(false)
  const [nativeAudioUrl, setNativeAudioUrl] = useState('')
  const [isPlayingNativeAudio, setIsPlayingNativeAudio] = useState(false)
  const [userDataFromDB, setUserDataFromDB] = useState({})
  const [allSuccessCounts, setAllSuccessCounts] = useState(getAllSuccessCounts(isSignedIn, userDataFromDB))
  const [successCounts, setSuccessCounts] = useState(getSuccessCounts(language, allSuccessCounts))
  const [prompts, setPrompts] = useState(getPrompts(isSignedIn, userDataFromDB))
  const [selectedPhraseList, setSelectedPhraseList] = useState([])
  const [analyserData, setAnalyserData] = useState({ data: [], lineTo: 0 })
  const [allCustomLists, setAllCustomLists] = useState(
    JSON.parse(localStorage.getItem('allCustomLists')) || {}
  )
  const [
    isPreviouslySelectedListAvailable,
    setIsPreviouslySelectedListAvailable
  ] = useState(selectedListName !== '')
  const [isOpenListSelector, setIsOpenListSelector] = useState(
    !isPreviouslySelectedListAvailable
  )
  const [isGlowingNative, setIsGlowingNative] = useState(false)
  const [isGlowingUser, setIsGlowingUser] = useState(false)
  const customListNames = Object.keys(allCustomLists[language] || {})
  const isSelectedListNameCustom = customListNames.includes(selectedListName)
  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false)
  const [logoutModalPosition, setLogoutModalPosition] = useState(null)
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(
    getUserEmailFromLocalStore()
  )

  const classes = useStyles()
  const sharedStyle = sharedStyles()

  const setLanguage = value => {
    _setLanguage(value)
    if (selectedListName) {
      setIsChoosingLanguage(false)
    }
    localStorage.setItem('language', value)
  }

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#insertion-point-jss')
    )
    localStorage.getItem('deviceId') ||
      localStorage.setItem('deviceId', uuidv4())
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    if (!isSignedIn || !loggedInUserEmail) {
      return
    }

    const fetchUserData = async () => {
      const userData = await getUserDataFromDB(loggedInUserEmail)
      setUserDataFromDB(userData)
    }
    fetchUserData()
  }, [loggedInUserEmail])

  useEffect(() => {
    if (!language) {
      return
    }

    setSuccessCounts(getSuccessCounts(language, allSuccessCounts))
  }, [language, allSuccessCounts])

  useEffect(() => {
    if (analyserData.lineTo > 190) {
      setStartTime(Date.now())
      setIsSpeaking(true)
    }
  }, [analyserData])

  useEffect(() => {
    if (!transcription) {
      return
    }
    const cleanPracticePhrase = cleanText(practicePhrase, language)
    const cleanTranscription = cleanText(transcription, language)
    const evaluatedPhraseParts = getEvaluatedPhraseParts(
      cleanTranscription,
      cleanPracticePhrase
    )
    setEvaluatedPhraseParts(evaluatedPhraseParts)
  }, [transcription])

  useEffect(() => {
    setTranscription('')
    setMatchConfidence(0)
    setMismatchCount(0)
    setFeedbackCollection([])

    if (!practicePhrase) {
      return
    }
    setPracticePhraseInLocalStore(language, selectedListName, practicePhrase)
  }, [practicePhrase])

  useEffect(() => {
    if (blobAudio) {
      setIsFeedbackShown(true)
    }
  }, [blobAudio])

  useEffect(() => {
    setIsFeedbackShown(false)
  }, [language, practicePhrase])

  useEffect(() => {
    if (!url) {
      return
    }
    const audioBlobId = url.substr(url.lastIndexOf('/') + 1)

    const feedback = { id: audioBlobId, url }
    const updatedCollection = update(feedbackCollection, {
      $splice: [[0, 0, feedback]]
    })
    const threeMostRecent = updatedCollection.slice(0, 3)
    const collapsedFeedback = threeMostRecent.map(feedback => ({
      ...feedback,
      expanded: false
    }))

    setFeedbackCollection(collapsedFeedback)
  }, [url])

  useEffect(() => {
    if (!url || !transcription) {
      return
    }

    const mostRecentFeedback = feedbackCollection[0] || {}
    const updatedFeedback = update(mostRecentFeedback, {
      $merge: {
        transcription,
        matchConfidence,
        accentConfidence,
        mismatchCount,
        expanded: true
      }
    })

    const updatedFeedbackCollection = update(feedbackCollection, {
      0: { $set: updatedFeedback }
    })

    setFeedbackCollection(updatedFeedbackCollection)
  }, [transcription, accentConfidence, matchConfidence, mismatchCount])

  useEffect(() => {
    if (isEmptyObject(allCustomLists)) {
      return
    }
    localStorage.setItem('allCustomLists', JSON.stringify(allCustomLists))

    if (!isSelectedListNameCustom) {
      return
    }

    if (!practicePhrase) {
      setPracticePhrase(selectedPhraseList[0])
    }
  }, [allCustomLists])

  useEffect(() => {
    if (!isSignedIn || Object.keys(userDataFromDB).length <= 0) {
      return
    }

    setAllCustomLists(getCustomLists(isSignedIn, userDataFromDB))
    setAllSuccessCounts(
      getAllSuccessCounts(isSignedIn, userDataFromDB)
    )
    setPrompts(getPrompts(isSignedIn, userDataFromDB))
  }, [userDataFromDB])

  return (
    <div className={classes.root}>
      {isOpenLoginDialog &&
        <Login
          isOpenLoginDialog={isOpenLoginDialog}
          setIsOpenLoginDialog={setIsOpenLoginDialog}
          setJwtAccessToken={setJwtAccessToken}
          isSignedIn={isSignedIn}
          setIsSignedIn={setIsSignedIn}
          setAlertMessage={setAlertMessage}
          setLoggedInUserEmail={setLoggedInUserEmail}
        />}
      <Logout
        anchorEl={logoutModalPosition}
        setAnchorEl={setLogoutModalPosition}
        setIsSignedIn={setIsSignedIn}
        setJwtAccessToken={setJwtAccessToken}
        loggedInUserEmail={loggedInUserEmail}
        setLoggedInUserEmail={setLoggedInUserEmail}
      />
      <HeaderBar
        language={language}
        isChoosingLanguage={isChoosingLanguage}
        setIsChoosingLanguage={setIsChoosingLanguage}
        setLanguageSelectVisible={setLanguageSelectVisible}
        isSignedIn={isSignedIn}
        setIsOpenLoginDialog={setIsOpenLoginDialog}
        setLogoutModalPosition={setLogoutModalPosition}
      />
      <Container>
        <Collapse
          in={isChoosingLanguage && languageSelectVisible}
          appear={false}
          onExited={() => setLanguageSelectVisible(false)}
          style={isChoosingLanguage ? { transitionDelay: 300 } : {}}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <LanguageSelector
              language={language}
              setLanguage={setLanguage}
              isOpen={isChoosingLanguage}
              setIsOpen={setIsChoosingLanguage}
            />
          </div>
        </Collapse>
        {language && (
          <div className={sharedStyle.mt1}>
            {selectedListName && (
              <>
                <Celebration active={isPhraseCompleted} type='small' />
                <Celebration active={isListCompleted} type='big' />
                <AudioPermission setStream={setStream} />
                <RecordingContextProvider
                  value={{
                    language,
                    isLoading,
                    setIsLoading,
                    transcription,
                    setTranscription,
                    practicePhrase,
                    setPracticePhrase,
                    successCount,
                    setSuccessCount,
                    isRecording,
                    setIsRecording,
                    blobAudio,
                    setBlobAudio,
                    startTime,
                    setStartTime,
                    stream,
                    setStream,
                    audioContext,
                    setAudioContext,
                    recorder,
                    setRecorder,
                    isSpeaking,
                    setIsSpeaking,
                    pauseTime,
                    setPauseTime,
                    setUrl,
                    setMatchConfidence,
                    setAccentConfidence,
                    setAlertMessage,
                    nativeAudioUrl,
                    isPlayingNativeAudio,
                    setIsPlayingNativeAudio,
                    selectedListName,
                    prompts,
                    setPrompts,
                    analyserData,
                    setAnalyserData,
                    isSelectedListNameCustom,
                    isGlowingNative,
                    setIsPlayingUserAudio,
                    loggedInUserEmail
                  }}
                >
                  <PracticeCard />
                </RecordingContextProvider>

                <Collapse in={mismatchCount === 3} mountOnEnter unmountOnExit>
                  <PracticeHintCard
                    language={language}
                    practicePhrase={practicePhrase}
                    setPracticePhrase={setPracticePhrase}
                    evaluatedPhraseParts={evaluatedPhraseParts}
                    mismatchCount={mismatchCount}
                    setMismatchCount={setMismatchCount}
                    allSuccessCounts={allSuccessCounts}
                    selectedPhraseList={selectedPhraseList}
                  />
                </Collapse>
                <Collapse in={isFeedbackShown} mountOnEnter unmountOnExit>
                  <FeedbackCard
                    language={language}
                    practicePhrase={practicePhrase}
                    transcription={transcription}
                    setMismatchCount={setMismatchCount}
                    evaluatedPhraseParts={evaluatedPhraseParts}
                    feedbackCollection={feedbackCollection}
                    setFeedbackCollection={setFeedbackCollection}
                    isPlayingUserAudio={isPlayingUserAudio}
                    setIsPlayingUserAudio={setIsPlayingUserAudio}
                    isPlayingNativeAudio={isPlayingNativeAudio}
                    setIsPlayingNativeAudio={setIsPlayingNativeAudio}
                    isGlowingNative={isGlowingNative}
                    isGlowingUser={isGlowingUser}
                    setIsGlowingUser={setIsGlowingUser}
                  />
                </Collapse>
              </>
            )}
            <div className={`${classes.phraseList} ${sharedStyle.mt1}`}>
              <PhraseList
                language={language}
                practicePhrase={practicePhrase}
                setPracticePhrase={setPracticePhrase}
                transcription={transcription}
                setIsListCompleted={setIsListCompleted}
                successCount={successCount}
                setSuccessCount={setSuccessCount}
                selectedListName={selectedListName}
                setSelectedListName={setSelectedListName}
                successCounts={successCounts}
                setSuccessCounts={setSuccessCounts}
                setIsPhraseCompleted={setIsPhraseCompleted}
                prompts={prompts}
                setPrompts={setPrompts}
                selectedPhraseList={selectedPhraseList}
                setSelectedPhraseList={setSelectedPhraseList}
                isOpenListSelector={isOpenListSelector}
                setIsOpenListSelector={setIsOpenListSelector}
                setIsChoosingLanguage={setIsChoosingLanguage}
                allCustomLists={allCustomLists}
                setAllCustomLists={setAllCustomLists}
                isPreviouslySelectedListAvailable={isPreviouslySelectedListAvailable}
                setIsPreviouslySelectedListAvailable={setIsPreviouslySelectedListAvailable}
                isSignedIn={isSignedIn}
                loggedInUserEmail={loggedInUserEmail}
                allSuccessCounts={allSuccessCounts}
                setAllSuccessCounts={setAllSuccessCounts}
              />
            </div>
            {selectedListName && (
              <>
                {alertMessage && (
                  <AlertSnackbar
                    message={alertMessage}
                    setMessage={setAlertMessage}
                  />
                )}
                <NativeAudioPlayer
                  language={language}
                  practicePhrase={practicePhrase}
                  voiceName={voiceName}
                  setVoiceName={setVoiceName}
                  setAlertMessage={setAlertMessage}
                  nativeAudioUrl={nativeAudioUrl}
                  setNativeAudioUrl={setNativeAudioUrl}
                  isPlayingNativeAudio={isPlayingNativeAudio}
                  setIsPlayingNativeAudio={setIsPlayingNativeAudio}
                  setIsGlowingNative={setIsGlowingNative}
                />
              </>
            )}
          </div>
        )}
      </Container>
    </div>
  )
}

export default App
