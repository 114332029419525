import React, { useEffect, useState } from 'react'
import update from 'immutability-helper'
import PronunciationDisplay from '../pronunciationDisplay/pronunciationDisplay'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PlayCircleOutlineTwoToneIcon from '@material-ui/icons/PlayCircleOutlineTwoTone'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography
} from '@material-ui/core'
import { useSwipeable } from 'react-swipeable'
import ClearIcon from '@material-ui/icons/Clear'
import HearingIcon from '@material-ui/icons/Hearing'
import sharedStyles from '../../sharedStyles'

const useStyles = makeStyles(() => ({
  accordion: {
    maxWidth: '46rem'
  },
  content: {
    display: 'block'
  },
  expandIcon: {
    left: 0,
    top: '5px',
    transition: 'top .1s ease 0s',
    position: 'absolute'
  },
  collapseIcon: {
    left: 0,
    top: '10px',
    transition: 'top .1s ease 0s',
    position: 'absolute'
  },
  dismiss: {
    position: 'absolute',
    right: 0,
    transition: 'top .1s ease 0s',
    top: '10px'
  },
  expandedDismiss: {
    position: 'absolute',
    right: 0,
    transition: 'top .1s ease 0s',
    top: '5px'
  },
  listenButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '14.5rem',
    height: '2.81rem'
  },
  playIcon: {
    margin: '2px 4px 2px 0px'
  },
  playUserAudioButton: {
    padding: '6px',
    minWidth: '6.28rem'
  },
  nativeAudioPlayer: {
    minWidth: '6.28rem'
  },
  hearIcon: {
    margin: '2px 4px 2px 0px'
  },
  listenNativeButton: {
    padding: '6px 6px',
    minWidth: 'inherit',
    height: '2.81rem'
  }
}))

const FeedbackCard = props => {
  const {
    language,
    practicePhrase,
    transcription,
    setMismatchCount,
    evaluatedPhraseParts,
    feedbackCollection,
    setFeedbackCollection,
    isPlayingUserAudio,
    setIsPlayingUserAudio,
    isPlayingNativeAudio,
    setIsPlayingNativeAudio,
    isGlowingNative,
    isGlowingUser,
    setIsGlowingUser
  } = props
  const [userAudioUrl, setUserAudioUrl] = useState('')
  const [activeCardIndex, setActiveCardIndex] = useState(null)
  const classes = useStyles()
  const sharedStyle = sharedStyles()

  useEffect(() => {
    if (feedbackCollection.length <= 0) {
      return
    }

    const mostRecentFeedback = feedbackCollection[0]
    setUserAudioUrl(mostRecentFeedback.url)
  }, [feedbackCollection])

  const toggleExpandCollapse = feedbackIndex => {
    const selectedFeedback = feedbackCollection[feedbackIndex]

    const updatedFeedbackCollection = update(feedbackCollection, {
      [feedbackIndex]: { $merge: { expanded: !selectedFeedback.expanded } }
    })

    setFeedbackCollection(updatedFeedbackCollection)
  }

  const dismissCard = feedbackIndex => {
    const updatedFeedbackCollection = update(feedbackCollection, {
      $splice: [[feedbackIndex, 1]]
    })
    setFeedbackCollection(updatedFeedbackCollection)
  }

  const toggleSelectedUserAudio = feedbackIndex => {
    const selectedAudioUrl = feedbackCollection[feedbackIndex].url
    setUserAudioUrl(selectedAudioUrl)
    setIsPlayingUserAudio(!isPlayingUserAudio)
  }

  const onAudioEnd = () => {
    setIsGlowingUser(false)
    setIsPlayingUserAudio(false)
  }

  const getSwipeAction = (action, selectedFeedback) => {
    if (action === 'Collapse' && selectedFeedback.expanded) {
      return false
    }
    if (action === 'Expand' && !selectedFeedback.expanded) {
      return true
    }
    return null
  }

  const toggleSwipe = (feedbackIndex, action) => {
    if (feedbackIndex == null) {
      return
    }

    const selectedFeedback = feedbackCollection[feedbackIndex] || {}
    const swipeAction = getSwipeAction(action, selectedFeedback)

    if (swipeAction !== null) {
      const updatedFeedbackCollection = update(feedbackCollection, {
        [feedbackIndex]: { $merge: { expanded: swipeAction } }
      })
      setFeedbackCollection(updatedFeedbackCollection)
    }
    if (swipeAction === null && action === 'Collapse') {
      dismissCard(feedbackIndex)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => toggleSwipe(activeCardIndex, 'Expand'),
    onSwipedRight: () => toggleSwipe(activeCardIndex, 'Collapse'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true
  })

  return (
    <div {...handlers}>
      {feedbackCollection.map((feedback, index) => (
        <Accordion
          expanded={feedback.expanded || false}
          className={classes.accordion}
          style={{ margin: '16px auto' }}
          data-testid='feedback-card'
          key={index}
          onMouseEnter={() => setActiveCardIndex(index)}
          onTouchStart={() => setActiveCardIndex(index)}
        >
          <AccordionSummary
            expandIcon={
              feedback.transcription &&
                <ExpandMoreIcon
                  aria-label={
                    feedback.expanded ? 'collapse card' : 'expand card'
                  }
                />
            }
            IconButtonProps={{
              onClick: () => toggleExpandCollapse(index)
            }}
            classes={{
              content: classes.content,
              expandIcon: feedback.expanded
                ? classes.expandIcon
                : classes.collapseIcon
            }}
          >
            <div
              aria-label='audio-player'
              className={`${classes.listenButtonsWrapper} ${sharedStyle.center}`}
            >
              <div className={classes.nativeAudioPlayer}>
                <Button
                  variant='contained'
                  className={
                    isGlowingNative
                      ? `${sharedStyle.glowOnPlay} ${classes.listenNativeButton}`
                      : classes.listenNativeButton
                  }
                  onClick={() => setIsPlayingNativeAudio(!isPlayingNativeAudio)}
                  aria-label='play native audio button'
                >
                  <HearingIcon className={classes.hearIcon} />
                  <Typography style={{ fontSize: '15px' }}>Native</Typography>
                </Button>
              </div>
              <Button
                variant='contained'
                className={
                  !isPlayingNativeAudio &&
                  isGlowingUser &&
                  userAudioUrl === feedback.url
                    ? sharedStyle.glowOnPlay
                    : sharedStyle.playUserAudioButton
                }
                onClick={() => toggleSelectedUserAudio(index)}
                aria-label='user audio player'
              >
                <PlayCircleOutlineTwoToneIcon className={classes.playIcon} />
                <Typography style={{ fontSize: '15px' }}>User</Typography>
              </Button>
            </div>
            <IconButton
              aria-label='dismiss feedback'
              onClick={() => dismissCard(index)}
              className={
                feedback.expanded ? classes.expandedDismiss : classes.dismiss
              }
            >
              <ClearIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            {feedback.transcription && transcription && (
              <PronunciationDisplay
                practicePhrase={practicePhrase}
                language={language}
                transcription={feedback.transcription}
                matchConfidence={feedback.matchConfidence}
                accentConfidence={feedback.accentConfidence}
                mismatchCount={feedback.mismatchCount}
                setMismatchCount={setMismatchCount}
                evaluatedPhraseParts={evaluatedPhraseParts}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {isPlayingUserAudio && userAudioUrl && !isPlayingNativeAudio && (
        <audio
          autoPlay
          src={userAudioUrl}
          onEnded={onAudioEnd}
          onPlay={() => setIsGlowingUser(true)}
        />
      )}
    </div>
  )
}

export default FeedbackCard
