import React, { useEffect } from 'react'
import { Button, Typography } from '@material-ui/core'
import { stopAudioContext } from '../../recorder/cancelRecordingButton/cancelRecordingButton'
import {
  DEFAULT_SERVER_ERROR_MESSAGE,
  SPEECH_API_BAD_RESPONSE_MESSAGE
} from '../../../constants/errorMessages'
import api from '../../../api'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  stopButton: {
    width: '6.25rem'
  }
}))

export const blobToFile = (theBlob, fileName) => {
  const file = new Blob([theBlob], {
    type: theBlob.type
  })
  file.name = fileName
  file.lastModifiedDate = new Date()

  return file
}

export const getTranscription = async (
  recording,
  language,
  practicePhrase,
  deviceId,
  loggedInUserEmail,
  setAlertMessage
) => {
  const formData = new FormData()
  const file = blobToFile(recording, 'audio.wav')
  formData.append('file', file)
  formData.append('language', language)
  formData.append('practicePhrase', practicePhrase)
  formData.append('deviceId', deviceId)
  formData.append('userName', loggedInUserEmail)

  return api
    .post('/speech', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Language': language
      }
    })
    .then(response => Promise.resolve(response))
    .catch(({ response }) => {
      let message = DEFAULT_SERVER_ERROR_MESSAGE
      if (response?.status === 400) {
        message = SPEECH_API_BAD_RESPONSE_MESSAGE
      }
      setAlertMessage(message)
    })
}

const StopRecordingButton = props => {
  const {
    recorder,
    setRecorder,
    language,
    practicePhrase,
    isSpeaking,
    setIsSpeaking,
    isRecording,
    setIsRecording,
    stream,
    setStream,
    audioContext,
    setAudioContext,
    startTime,
    setStartTime,
    pauseTime,
    setPauseTime,
    setIsLoading,
    setBlobAudio,
    setTranscription,
    setMatchConfidence,
    blobAudio,
    setUrl,
    setAccentConfidence,
    setAlertMessage,
    setIsPlayingNativeAudio,
    setIsPlayingUserAudio,
    loggedInUserEmail
  } = props
  const deviceId = localStorage.getItem('deviceId')
  const classes = useStyles()

  useEffect(() => {
    const duration = isSpeaking ? 1 : 5
    setPauseTime(duration)
  }, [isSpeaking])

  useEffect(() => {
    if (blobAudio) {
      const url = window.URL.createObjectURL(blobAudio)
      setUrl(url)
    }
  }, [blobAudio])

  useEffect(() => {
    if (isRecording && startTime) {
      const timer = setInterval(() => {
        if (Math.floor((Date.now() - startTime) / 1000) >= pauseTime) {
          submitRecording()
        }
      }, 500)
      return () => {
        clearInterval(timer)
      }
    }
  }, [isRecording, startTime, pauseTime])

  const autoPlayAudios = () => {
    setIsPlayingNativeAudio(true)
    setIsPlayingUserAudio(true)
  }
  const stopRecording = () => {
    const audioContextSuspended = stopAudioContext(audioContext, stream, setStream)
    setIsSpeaking(false)
    setStartTime('')
    setAudioContext(audioContextSuspended)
  }

  const submitRecording = async () => {
    autoPlayAudios()
    stopRecording()
    recorder.stop().then(async ({ blob }) => {
      await setIsLoading(true)
      await setBlobAudio(blob)
      await setIsRecording(false)
      const transcription = await getTranscription(
        blob,
        language,
        practicePhrase,
        deviceId,
        loggedInUserEmail,
        setAlertMessage
      )
      recorder.setOnAnalysed(null)
      setRecorder(recorder)
      await setIsLoading(false)
      if (transcription) {
        const responseData = transcription.data
        setTranscription(responseData.transcriptedText)
        setMatchConfidence(responseData.matchConfidence)
        setAccentConfidence(responseData.accentConfidence)
        console.log(responseData)
      }
    })
  }

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        aria-label='stop-button'
        className={classes.stopButton}
        onClick={submitRecording}
      >
        <Typography>Stop</Typography>
      </Button>
    </>
  )
}

export default StopRecordingButton
