import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import update from 'immutability-helper'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_BASE_URL
})
// declare a response interceptor
api.interceptors.response.use(
  response => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      const updatedResponse = update(response, {
        data: { $set: camelcaseKeys(response.data) }
      })
      return updatedResponse
    }
    return response
  },
  error => {
    // handle the response error
    console.log('error', error)
    return Promise.reject(error)
  }
)

export default api
