import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  pathStyle: {
    strokeLinecap: 'square',
    strokeWidth: 0.75,
    stroke: '#616161'
  },
  pathStyle2: {
    strokeLinecap: 'square',
    stroke: theme.palette.grey[700],
    strokeWidth: 1
  },
  svgStyle: {
    width: '210px',
    height: '50px',
    backgroundColor: theme.palette.grey[300],
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '5px'
  }
}))

const findLastNonZero = data => {
  let idx = data.length - 1

  for (; idx >= 0; idx -= 1) {
    if (data[idx] !== 0) {
      return idx
    }
  }

  return 0
}

const AudioVisualizer = props => {
  const ref = createRef()
  const classes = useStyles()
  const shouldDraw = (idx, datum) => {
    const { lineTo } = props

    return !(
      datum === 0 ||
      idx % 2 === 1 ||
      idx > lineTo ||
      idx < props.drawOffset ||
      idx > 255 - props.drawOffset
    )
  }

  const { data } = props
  let lineTo = props.lineTo

  if (!lineTo) {
    lineTo = findLastNonZero(data)
  }

  return (
    <svg
      id='visualizer'
      ref={ref}
      viewBox='0 0 255 255'
      preserveAspectRatio='none'
      className={classes.svgStyle}
    >
      <path className={classes.pathStyle} d={`M 0, ${255 / 2} l 255, 0`} />
      {data.map((datum, idx) =>
        !shouldDraw(idx, datum) ? null : (
          <path
            key={idx}
            className={classes.pathStyle}
            d={`M ${idx},${255 / 2} l 0,-${datum / 2}`}
          />
        )
      )}
      {data.map((datum, idx) =>
        !shouldDraw(idx, datum) ? null : (
          <path
            key={idx}
            className={classes.pathStyle}
            d={`M ${idx},${255 / 2} l 0,${datum / 2}`}
          />
        )
      )}
      <path
        className={classes.pathStyle2}
        d={`M 0, ${255 / 2} l ${lineTo}, 0`}
      />
    </svg>
  )
}

AudioVisualizer.propTypes = {
  lineTo: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  drawOffset: PropTypes.number
}

AudioVisualizer.defaultProps = {
  drawOffset: 12,
  data: [],
  lineTo: 255
}

export default AudioVisualizer
