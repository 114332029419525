import React, { useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

const Alert = props => {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  snackbar: {
    top: 50
  }
}))

const AlertSnackbar = ({ message, setMessage }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    setMessage(null)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={message}
        className={classes.snackbar}
      >
        <Alert onClose={handleClose} severity='error'>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AlertSnackbar
