import React from 'react'
import { findBestMatch } from 'string-similarity'
import { cleanText } from '../../helpers'
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import sharedStyles from '../../sharedStyles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '43.75rem',
    padding: '1rem',
    position: 'relative',
    display: 'flex'
  },
  cardContent: {
    padding: 0,
    flexGrow: 1,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  paperWrapper: {
    display: 'flex',
    flexFlow: 'wrap',
    [theme.breakpoints.down('xs')]: {
      width: '295px'
    },
    justifyContent: 'center'
  },
  paper: {
    padding: '5px 16px',
    margin: '0 10px',
    maxWidth: 'fit-content',
    cursor: 'pointer',
    height: 'fit-content'
  },
  message: {
    padding: '0.5rem 1rem'
  },
  clearIcon: {
    position: 'absolute',
    right: 0,
    top: '12px',
    [theme.breakpoints.down('sm')]: {
      top: '5px'
    }
  },
  expandedClearIcon: {
    position: 'absolute',
    right: 0,
    top: '29px',
    [theme.breakpoints.down('sm')]: {
      top: '5px'
    }
  }
}))

const MESSAGES = {
  none: '',
  shortPhrase:
    'Sometimes it helps to practice other phrases with similar sounds and then come back',
  longPhrase:
    'Sometimes it helps to practice a smaller chunk of this phrase, like these:'
}

const isSingleWord = phrase => phrase.split(' ').length === 1
const unique = elements => [...new Set(elements)]

const getMispronouncedPhraseParts = evaluatedPhraseParts => {
  return evaluatedPhraseParts
    .filter(phrasePart => phrasePart.type === 'mispronounced')
    .map(phrasePart => phrasePart.text)
}

const getPartialPhrasesWithMispronouncedWords = (
  mispronouncedParts,
  practicePhrase
) => {
  const returnPhrases = []
  const practicePhraseWords = practicePhrase.split(' ')
  const wordCount = practicePhraseWords.length
  let index = 0

  mispronouncedParts.forEach(mispronouncedPhrasePart => {
    const remainingWords = practicePhraseWords.slice(index, wordCount)
    index = remainingWords.indexOf(mispronouncedPhrasePart)

    const partialPhraseWithMispronouncedWord = remainingWords
      .slice(0, index + 1)
      .join(' ')
    if (partialPhraseWithMispronouncedWord) {
      returnPhrases.push(partialPhraseWithMispronouncedWord)
    }
  })

  return returnPhrases
}

export const getSuggestedPhraseParts = (practicePhrase, mispronouncedParts) => {
  if (isSingleWord(practicePhrase)) {
    return []
  }

  const entirePhraseMispronounced =
    mispronouncedParts.length === 1 && mispronouncedParts[0] === practicePhrase

  if (entirePhraseMispronounced) {
    return practicePhrase.split(' ')
  }

  const suggestedPhrases = [
    ...mispronouncedParts,
    ...getPartialPhrasesWithMispronouncedWords(
      mispronouncedParts,
      practicePhrase
    )
  ]

  return unique(suggestedPhrases)
}

const getHint = (suggestedPhraseParts, mismatchCount) => {
  if (mismatchCount < 3) {
    return MESSAGES.none
  }

  if (suggestedPhraseParts.length > 0) {
    return MESSAGES.longPhrase
  } else {
    return MESSAGES.shortPhrase
  }
}

const getSimilarPhrases = (
  practicePhrase,
  selectedPhraseList,
  allSuccessCounts
) => {
  if (!practicePhrase || selectedPhraseList.length <= 0) {
    return []
  }
  const toLowerCase = phrase => phrase.toLowerCase()

  const matches = findBestMatch(
    practicePhrase.toLowerCase(),
    selectedPhraseList.map(toLowerCase)
  )

  const filteredRating = matches.ratings
    .filter(
      ({ target, rating }) => rating > 0.05 && allSuccessCounts[target] !== 3
    )
    .sort((a, b) => b.rating - a.rating)

  return filteredRating.slice(1, 4).map(({ target }) => target)
}

const PracticeHintCard = ({
  language,
  practicePhrase,
  setPracticePhrase,
  evaluatedPhraseParts,
  mismatchCount,
  setMismatchCount,
  allSuccessCounts,
  selectedPhraseList
}) => {
  const classes = useStyles()
  const sharedStyle = sharedStyles()

  const cleanPracticePhrase = cleanText(practicePhrase, language)
  const mispronouncedPhraseParts = getMispronouncedPhraseParts(
    evaluatedPhraseParts
  )
  const suggestedPhraseParts = getSuggestedPhraseParts(
    cleanPracticePhrase,
    mispronouncedPhraseParts
  )
  const hint = getHint(suggestedPhraseParts, mismatchCount)

  const similarPhrases =
    (suggestedPhraseParts.length <= 0 &&
      getSimilarPhrases(
        practicePhrase,
        selectedPhraseList,
        allSuccessCounts
      )) ||
    []

  return (
    <Card
      className={`${classes.card} ${sharedStyle.center} ${sharedStyle.mt1}`}
      data-testid='hint-card'
    >
      <CardContent className={classes.cardContent}>
        <Typography className={classes.message}>{hint}</Typography>
        <div className={`${classes.paperWrapper} ${sharedStyle.center}`}>
          {suggestedPhraseParts.map((part, index) => (
            <Paper
              className={classes.paper}
              onClick={() => setPracticePhrase(part)}
              key={index}
            >
              <Typography>{part}</Typography>
            </Paper>
          ))}

          {similarPhrases.map((phrase, index) => (
            <Paper
              className={classes.paper}
              onClick={() => setPracticePhrase(phrase)}
              key={index}
            >
              <Typography>{phrase}</Typography>
            </Paper>
          ))}
        </div>
        <IconButton
          aria-label='dismiss hint'
          onClick={() => setMismatchCount(0)}
          className={
            suggestedPhraseParts.length > 0
              ? classes.expandedClearIcon
              : classes.clearIcon
          }
        >
          <ClearIcon />
        </IconButton>
      </CardContent>
    </Card>
  )
}

export default PracticeHintCard
