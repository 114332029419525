import React from 'react'
import StartRecordingButton from '../recorder/startRecordingButton/startRecordingButton'
import SuccessCounter from '../successCounter/successCounter'
import {
  CardContent,
  CardActions,
  LinearProgress,
  IconButton,
  Fade,
  Button,
  Typography,
  Paper
} from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'
import HearingIcon from '@material-ui/icons/Hearing'
import sharedStyles from '../../sharedStyles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  cardAction: {
    justifyContent: 'center',
    display: 'unset'
  },
  loader: {
    width: '17.5rem',
    margin: '0 auto',
    marginTop: '2rem'
  },
  practiceListenButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '17rem',
    marginTop: '4rem',
    height: '2.81rem'
  },
  editButton: {
    top: 0,
    right: 0,
    padding: '14px',
    margin: '10px',
    position: 'absolute'
  },
  practicePhrase: {
    fontSize: '18px',
    padding: '15px 10px 10px 10px',
    width: 'fit-content'
  },
  practicePhrasePaper: {
    width: 'fit-content',
    margin: '0 auto'
  },
  successCounter: {
    padding: '1rem',
    position: 'absolute',
    left: 0,
    bottom: 0
  },
  starEditCard: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    margin: '6px'
  },
  filledStarIcon: {
    color: theme.palette.warning.main
  },
  cursor: {
    cursor: 'pointer'
  }
}))

const ViewMode = props => {
  const {
    practicePhrase,
    isLoading,
    successCount,
    setIsEditing,
    isPlayingNativeAudio,
    setIsPlayingNativeAudio,
    prompts,
    isSelectedListNameCustom,
    isGlowingNative,
    selectedListName
  } = props
  const classes = useStyles()
  const sharedStyle = sharedStyles()

  const isPromptAvailable = (prompts, selectedListName, practicePhrase) => {
    return Boolean(prompts[selectedListName] && prompts[selectedListName][practicePhrase])
  }

  const getPracticeText = (isSelectedListNameCustom, practicePhrase, selectedListName, prompts) => {
    if (!practicePhrase) {
      return
    }

    if (!isSelectedListNameCustom || !isPromptAvailable(prompts, selectedListName, practicePhrase)) {
      return practicePhrase
    }

    return prompts[selectedListName][practicePhrase]
  }

  return (
    <div data-testid='practice-card-view-mode'>
      {isSelectedListNameCustom && (
        <IconButton
          className={classes.editButton}
          aria-label='edit practice card'
          onClick={() => setIsEditing(true)}
        >
          <Edit />
        </IconButton>
      )}
      <CardContent>
        <Paper
          className={`${
            classes.practicePhrasePaper
          } ${isSelectedListNameCustom && classes.cursor}`}
          onClick={() => isSelectedListNameCustom && setIsEditing(true)}
        >
          <Typography align='center' className={classes.practicePhrase}>
            {getPracticeText(isSelectedListNameCustom, practicePhrase, selectedListName, prompts)}
          </Typography>
        </Paper>
      </CardContent>
      <CardActions className={classes.cardAction}>
        {isLoading && (
          <Fade in={isLoading} mountOnEnter unmountOnExit>
            <LinearProgress className={classes.loader} />
          </Fade>
        )}

        {!isLoading && (
          <Fade in={!isLoading}>
            <div
              className={`${classes.practiceListenButtonWrapper} ${sharedStyle.center}`}
            >
              <Button
                variant='contained'
                className={isGlowingNative ? sharedStyle.glowOnPlay : ''}
                style={{ width: '7.25rem', height: '2.81rem' }}
                onClick={() => setIsPlayingNativeAudio(!isPlayingNativeAudio)}
                aria-label='play native audio button'
              >
                <HearingIcon className={classes.hearIcon} />
                <Typography style={{ fontSize: '15px' }}>Native</Typography>
              </Button>
              <StartRecordingButton />
            </div>
          </Fade>
        )}
      </CardActions>
      <div className={classes.successCounter}>
        <SuccessCounter
          value={successCount}
          size={40}
          toolTipPlacement='top-start'
          bgColor='#e0e0e0'
        />
      </div>
    </div>
  )
}

export default ViewMode
