import { makeStyles } from '@material-ui/core/styles'

const sharedStyles = makeStyles(theme => ({
  center: {
    margin: '0 auto'
  },
  floatLeft: {
    float: 'left'
  },
  dBlock: {
    display: 'block'
  },
  mr1: {
    marginRight: '1rem'
  },
  mt1: {
    marginTop: '1rem'
  },
  mt2: {
    marginTop: '2rem'
  },
  mt3: {
    marginTop: '3rem'
  },
  fontLarge: {
    fontSize: '30px'
  },
  fontMedium: {
    fontSize: '15px'
  },
  verticalCenter: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  dInlineBlock: {
    display: 'inline-block'
  },
  dFlex: {
    display: 'flex'
  },
  justifyAround: {
    justifyContent: 'space-around'
  },
  glowOnPlay: {
    width: 'inherit',
    height: 'inherit',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 0,
    borderRadius: '5px',
    '&:before': {
      content: '""',
      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.warning.light}, ${theme.palette.info.main}, ${theme.palette.success.main}, ${theme.palette.primary.main} )`,
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      backgroundSize: '400%',
      zIndex: '-1',
      filter: 'blur(3px)',
      width: 'calc(100% + 4px)',
      height: 'calc(100% + 4px)',
      animation: '$glowing 20s linear infinite',
      transition: `opacity 0.3s ${theme.transitions.easing.easeInOut}`,
      borderRadius: '5px'
    },
    '&:active': {
      color: '#000',
      '&:after': {
        background: 'transparent'
      }
    },
    '&:after': {
      zIndex: '-1',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'inherit',
      left: 0,
      top: 0,
      borderRadius: '5px'
    }
  },

  '@keyframes glowing': {
    '0%': {
      backgroundPosition: '0 0'
    },
    '50%': {
      backgroundPosition: '400% 0'
    },
    '100%': {
      backgroundPosition: '0 0'
    }
  }
}))

export default sharedStyles
