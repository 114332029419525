import { useEffect, useState } from 'react'
import getAudioStream from './audioStream'

const AudioPermission = ({ setStream }) => {
  const [havePermission, setHavePermission] = useState(false)

  const checkPermission = async () => {
    const stream = await getAudioStream()
    if (stream) {
      stream.getTracks().forEach(track => (track.enabled = false))
      setStream(stream)
      setHavePermission(true)
    }
  }

  useEffect(() => {
    if (havePermission) {
      return
    }

    checkPermission()
  }, [havePermission])

  return null
}

export default AudioPermission
