import React, { useEffect, useState } from 'react'
import { isMatch, cleanText } from '../../helpers'
import { Typography } from '@material-ui/core'
import sharedStyles from '../../sharedStyles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  green: {
    color: theme.palette.success.dark
  },
  red: {
    color: theme.palette.error.main
  },
  orange: {
    color: theme.palette.warning.dark
  },
  emojiWrapper: {
    marginTop: '10px'
  },
  emoji: {
    textShadow: '0 0 0 rgb(245, 195, 58)',
    fontSize: '30px',
    display: 'inline-block'
  },
  evaluatedWords: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}))

const getEmoji = (
  hasPartialMatch,
  isExactMatch,
  accentConfidence,
  matchConfidence
) => {
  if (!hasPartialMatch && !isExactMatch) {
    return '🙁️'
  }

  if (
    (accentConfidence > 0 && accentConfidence < 50) ||
    (matchConfidence > 0 && matchConfidence < 50)
  ) {
    return '😐'
  }
  if (
    (accentConfidence > 0 && accentConfidence < 80) ||
    (matchConfidence > 0 && matchConfidence < 80)
  ) {
    return '🙂'
  }
  if (isExactMatch) {
    return '😀'
  }

  return '😐'
}
const getTextColor = (
  hasPartialMatch,
  isExactMatch,
  accentConfidence,
  matchConfidence
) => {
  if (!hasPartialMatch && !isExactMatch) {
    return 'red'
  }
  if (
    !isExactMatch ||
    (accentConfidence > 0 && accentConfidence < 50) ||
    (matchConfidence > 0 && matchConfidence < 50)
  ) {
    return 'orange'
  }

  return 'green'
}

const getMatchConfidenceMessage = (matchConfidence, isExactMatch) => {
  if (matchConfidence === 0 || !isExactMatch) {
    return
  }

  if (matchConfidence >= 80) {
    return 'I can understand you perfectly.'
  }
  if (matchConfidence >= 50) {
    return 'I can understand you just fine.'
  }
  if (matchConfidence >= 30) {
    return "I'm not totally sure I understood you."
  }
  if (matchConfidence < 30) {
    return 'I understood you, more or less.'
  }
}

const getAccentConfidenceMessage = accentConfidence => {
  if (accentConfidence === 0) {
    return
  }

  if (accentConfidence >= 80) {
    return 'Your accent is perfect!'
  }
  if (accentConfidence >= 50) {
    return 'Your accent is good.'
  }
  if (accentConfidence >= 30) {
    return 'I think your accent is off.'
  }
  if (accentConfidence < 30) {
    return 'Your accent is definitely off.'
  }
}

const getJoiningWord = (accentConfidence, matchConfidence) => {
  if (!accentConfidence || !matchConfidence) {
    return ''
  }

  if (accentConfidence >= 50 && matchConfidence < 50) {
    return 'However,'
  }
  if (matchConfidence >= 50 && accentConfidence < 50) {
    return 'However,'
  }
  return ''
}

const getMessage = (
  hasPartialMatch,
  isExactMatch,
  accentConfidence,
  matchConfidence
) => {
  if (!hasPartialMatch && !isExactMatch) {
    return "I didn't understand that at all"
  }

  if (!isExactMatch && hasPartialMatch) {
    return 'I think maybe I misheard you, can you say that again?'
  }

  const accentConfidenceMessage = getAccentConfidenceMessage(accentConfidence)
  const matchConfidenceMessage = getMatchConfidenceMessage(
    matchConfidence,
    isExactMatch
  )
  const joiningWord = getJoiningWord(accentConfidence, matchConfidence)
  return [accentConfidenceMessage, joiningWord, matchConfidenceMessage]
    .filter(Boolean)
    .join(' ')
}

export const getDisplayInfo = (
  hasPartialMatch,
  isExactMatch,
  accentConfidence,
  matchConfidence
) => {
  const emoji = getEmoji(
    hasPartialMatch,
    isExactMatch,
    accentConfidence,
    matchConfidence
  )
  const textColor = getTextColor(
    hasPartialMatch,
    isExactMatch,
    accentConfidence,
    matchConfidence
  )
  const message = getMessage(
    hasPartialMatch,
    isExactMatch,
    accentConfidence,
    matchConfidence
  )

  return { emoji, textColor, message }
}

export const getEvaluations = (evaluatedPhraseParts, cleanPracticePhrase) => {
  const practicePhraseWords = cleanPracticePhrase.split(' ')
  const evaluation = []
  evaluatedPhraseParts.forEach(({ type, text }) => {
    text.split(' ').forEach(word => {
      const isMissingWord =
        practicePhraseWords.indexOf(word) !== -1 && type === 'mispronounced'
      evaluation.push({
        type,
        word,
        missing: isMissingWord,
        display: isMissingWord ? ' ____ ' : word
      })
    })
  })

  return evaluation
}

const PronunciationDisplay = props => {
  const {
    transcription,
    practicePhrase,
    language,
    matchConfidence,
    accentConfidence,
    mismatchCount,
    setMismatchCount,
    evaluatedPhraseParts
  } = props
  const [hasPartialMatch, setHasPartialMatch] = useState(false)
  const [isExactMatch, setIsExactMatch] = useState(false)
  const [evaluation, setEvaluation] = useState({})
  const classes = useStyles()
  const sharedStyle = sharedStyles()

  useEffect(() => {
    if (!transcription) {
      return
    }

    const cleanPracticePhrase = cleanText(practicePhrase, language)
    const cleanTranscription = cleanText(transcription, language)
    const evaluation = getEvaluations(evaluatedPhraseParts, cleanPracticePhrase)
    setEvaluation({ transcription, practicePhrase, words: evaluation })
    setIsExactMatch(isMatch(cleanPracticePhrase, cleanTranscription, language))
  }, [transcription])

  useEffect(() => {
    if (Object.keys(evaluation).length === 0) {
      return
    }
    setHasPartialMatch(
      evaluation.words.some(evaluation => evaluation.type === 'correct')
    )
  }, [evaluation])

  useEffect(() => {
    if (isExactMatch) {
      setMismatchCount(0)
    } else {
      setMismatchCount(mismatchCount + 1)
    }
  }, [isExactMatch])

  const { message, emoji, textColor } = getDisplayInfo(
    hasPartialMatch,
    isExactMatch,
    accentConfidence,
    matchConfidence
  )

  const getEvaluatedWordColor = type => {
    if (type === 'correct') {
      return 'green'
    } else if (type === 'extra') {
      return 'orange'
    } else {
      return 'red'
    }
  }

  return (
    <div data-testid='pronunciation-data'>
      {isExactMatch ? (
        <Typography component='span' className={classes.green}>
          {practicePhrase}
        </Typography>
      ) : (
        <div className={classes.evaluatedWords}>
          {Object.keys(evaluation).length !== 0 &&
            evaluation.words.map(({ type, display }, index) => (
              <Typography
                component='span'
                className={classes[getEvaluatedWordColor(type)]}
                key={index}
                style={{ margin: 2 }}
                noWrap
              >
                {display}
              </Typography>
            ))}
        </div>
      )}

      {message && (
        <div className={classes.emojiWrapper}>
          <span className={`${classes.emoji}${sharedStyle.mr1}`}>{emoji}</span>
          <Typography className={classes[textColor]}>{message}</Typography>
        </div>
      )}
    </div>
  )
}

export default PronunciationDisplay
