import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { GoogleLogin } from 'react-google-login'
import { Dialog, Slide, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

const LOGIN_ERROR_MESSAGE = 'Failed to login'

const useStyles = makeStyles(() => ({
  card: {
    width: '18.75rem',
    height: '12.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  deviceId: {
    display: 'flex',
    fontSize: '8px',
    marginRight: '8px',
    marginLeft: 'auto'
  }
}))

const getDeviceId = () => localStorage.getItem('deviceId') || ''

const Login = ({
  isOpenLoginDialog,
  setIsOpenLoginDialog,
  setJwtAccessToken,
  isSignedIn,
  setIsSignedIn,
  setAlertMessage,
  setLoggedInUserEmail
}) => {
  const classes = useStyles()
  const deviceId = getDeviceId()
  const Transition = forwardRef((props, ref) => {
    return <Slide direction='down' ref={ref} {...props} />
  })

  const updateToken = response => {
    setJwtAccessToken(response.accessToken)
    const user = {
      accessToken: response.accessToken,
      email: response.profileObj.email,
      name: response.profileObj.name
    }
    localStorage.setItem('user', JSON.stringify(user))
  }

  const onSuccess = response => {
    closeDialog()
    updateToken(response)
    refreshTokenSetup(response)
    setIsSignedIn(true)
    setLoggedInUserEmail(response.profileObj.email)
  }

  const onFailure = response => {
    closeDialog()
    setAlertMessage(LOGIN_ERROR_MESSAGE)
    console.log('failed', response)
  }

  const refreshTokenSetup = res => {
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000

    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse()
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000
      updateToken(newAuthRes)
      setTimeout(refreshToken, refreshTiming)
    }

    setTimeout(refreshToken, refreshTiming)
  }

  const closeDialog = () => {
    setIsOpenLoginDialog(false)
  }

  return (
    <Dialog
      open={isOpenLoginDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
    >
      <div className={classes.card}>
        <IconButton
          className={classes.close}
          onClick={closeDialog}
          aria-label='close login dialog'
        >
          <CloseIcon />
        </IconButton>
        <GoogleLogin
          clientId={process.env.REACT_APP_O_AUTH_CLIENT_ID}
          buttonText='Login with Google'
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy='single_host_origin'
          isSignedIn={isSignedIn}
        />
      </div>
      <div aria-label='device id' className={classes.deviceId}>
        <p style={{ marginRight: '5px' }}>Device Id:</p>
        <p style={{ fontWeight: 'bold' }}>{deviceId}</p>
      </div>
    </Dialog>
  )
}

Login.prototype = {
  isOpenLoginDialog: PropTypes.bool.isRequired,
  setIsOpenLoginDialog: PropTypes.func.isRequired,
  setJwtAccessToken: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  setIsSignedIn: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired
}

export default Login
