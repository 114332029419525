import React, { useState, useContext, useEffect } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { isMatch } from '../../helpers'
import AudioVisualizer from '../audioVisualizer/audioVisualizer'
import CancelRecordingButton from '../recorder/cancelRecordingButton/cancelRecordingButton'
import StopRecordingButton from '../recorder/stopRecordingButton/stopRecordingButton'
import RecorderIndicator from '../recorderIndicator/recorderIndicator'
import EditMode from './editMode'
import ViewMode from './viewMode'
import { RecordingContext } from '../../context/recordingContext/recordingContext'
import sharedStyles from '../../sharedStyles'
import { Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  recordCard: {
    maxWidth: '43.75rem',
    padding: '2rem 1rem',
    position: 'relative',
    justifyContent: 'space-around'
  },
  fadeAppear: {
    willChange: 'opacity, transition'
  },
  fadeEnter: {
    opacity: 0
  },
  fadeEnterActive: {
    opacity: 1,
    transition: 'opacity 110ms cubic-bezier(0, 0, 0.2, 1) 40ms'
  },
  fadeExit: {
    opacity: 1
  },
  fadeExitActive: {
    opacity: 0,
    transition: 'opacity 40ms cubic-bezier(0.4, 0, 1, 1)'
  },
  expanded: {
    height: 307
  },
  recordingCardContent: {
    height: 307,
    margin: '0 auto'
  },
  visualizer: {
    width: 'fit-content',
    margin: 'auto'
  },
  stopCancelButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '14.5rem',
    marginTop: '4rem',
    height: '2.81rem'
  },
  listenNativeButton: {
    padding: '6px 6px',
    minWidth: 'inherit',
    height: '2.81rem'
  }
}))

const PracticeCard = () => {
  const {
    language,
    isLoading,
    transcription,
    practicePhrase,
    setPracticePhrase,
    recorder,
    setRecorder,
    pauseTime,
    setPauseTime,
    isRecording,
    setIsRecording,
    stream,
    setStream,
    audioContext,
    setAudioContext,
    startTime,
    setStartTime,
    isSpeaking,
    setIsSpeaking,
    setIsLoading,
    blobAudio,
    setBlobAudio,
    setTranscription,
    setMatchConfidence,
    setUrl,
    setAccentConfidence,
    successCount,
    setSuccessCount,
    setAlertMessage,
    isPlayingNativeAudio,
    setIsPlayingNativeAudio,
    selectedListName,
    prompts,
    setPrompts,
    analyserData,
    isSelectedListNameCustom,
    isGlowingNative,
    setIsPlayingUserAudio,
    loggedInUserEmail
  } = useContext(RecordingContext)
  const [isEditing, setIsEditing] = useState(false)
  const classes = useStyles()
  const sharedStyle = sharedStyles()

  useEffect(() => {
    if (!transcription) {
      return
    }

    const isMatched = isMatch(practicePhrase, transcription, language)

    if (!isMatched) {
      setSuccessCount(0)
      return
    }

    if (successCount < 3) {
      setSuccessCount(successCount => successCount + 1)
    }
  }, [transcription])

  return (
    <Card
      className={`${classes.recordCard} ${sharedStyle.center}`}
      data-testid='practice-card'
    >
      <div className={classes.expanded}>
        <SwitchTransition>
          <CSSTransition
            timeout={150}
            key={isRecording ? 'recording' : 'editing'}
            classNames={{
              appear: classes.fadeAppear,
              enter: classes.fadeEnter,
              enterActive: classes.fadeEnterActive,
              exit: classes.fadeExit,
              exitActive: classes.fadeExitActive
            }}
          >
            <>
              {isRecording ? (
                <CardContent className={classes.recordingCardContent}>
                  <div className={classes.visualizer}>
                    <AudioVisualizer {...analyserData} />
                  </div>

                  <RecorderIndicator />

                  <div
                    className={`${classes.stopCancelButtonWrapper} ${sharedStyle.center}`}
                  >
                    <CancelRecordingButton
                      setIsRecording={setIsRecording}
                      stream={stream}
                      setStream={setStream}
                      audioContext={audioContext}
                      setAudioContext={setAudioContext}
                      setStartTime={setStartTime}
                      setIsSpeaking={setIsSpeaking}
                      recorder={recorder}
                      setRecorder={setRecorder}
                    />
                    <StopRecordingButton
                      recorder={recorder}
                      setRecorder={setRecorder}
                      language={language}
                      practicePhrase={practicePhrase}
                      isSpeaking={isSpeaking}
                      setIsSpeaking={setIsSpeaking}
                      isRecording={isRecording}
                      setIsRecording={setIsRecording}
                      stream={stream}
                      setStream={setStream}
                      audioContext={audioContext}
                      setAudioContext={setAudioContext}
                      startTime={startTime}
                      setStartTime={setStartTime}
                      pauseTime={pauseTime}
                      setPauseTime={setPauseTime}
                      setIsLoading={setIsLoading}
                      setBlobAudio={setBlobAudio}
                      setTranscription={setTranscription}
                      setMatchConfidence={setMatchConfidence}
                      blobAudio={blobAudio}
                      setUrl={setUrl}
                      setAccentConfidence={setAccentConfidence}
                      setAlertMessage={setAlertMessage}
                      setIsPlayingNativeAudio={setIsPlayingNativeAudio}
                      setIsPlayingUserAudio={setIsPlayingUserAudio}
                      loggedInUserEmail={loggedInUserEmail}
                    />
                  </div>
                </CardContent>
              ) : (
                <SwitchTransition>
                  <CSSTransition
                    timeout={150}
                    key={isEditing ? 'editmode' : 'viewmode'}
                    classNames={{
                      enter: classes.fadeEnter,
                      enterActive: classes.fadeEnterActive,
                      exit: classes.fadeExit,
                      exitActive: classes.fadeExitActive
                    }}
                  >
                    <>
                      {!isEditing && practicePhrase ? (
                        <ViewMode
                          language={language}
                          practicePhrase={practicePhrase}
                          isLoading={isLoading}
                          successCount={successCount}
                          selectedListName={selectedListName}
                          setIsEditing={setIsEditing}
                          isPlayingNativeAudio={isPlayingNativeAudio}
                          setIsPlayingNativeAudio={setIsPlayingNativeAudio}
                          prompts={prompts}
                          isSelectedListNameCustom={isSelectedListNameCustom}
                          isGlowingNative={isGlowingNative}
                        />
                      ) : (
                        <EditMode
                          practicePhrase={practicePhrase}
                          setPracticePhrase={setPracticePhrase}
                          setIsEditing={setIsEditing}
                          prompts={prompts}
                          setPrompts={setPrompts}
                          selectedListName={selectedListName}
                        />
                      )}
                    </>
                  </CSSTransition>
                </SwitchTransition>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Card>
  )
}

export default PracticeCard
