import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  delete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    minWidth: '6rem'
  },
  cancel: {
    minWidth: '6rem'
  }
}))

const ConfirmationDialog = props => {
  const { value, open, onClose, onCancel } = props
  const classes = useStyles()

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      data-testid='confirmation-dialog'
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent dividers>
        <Typography>{`Are you sure you want to delete ${value}?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onCancel}
          variant='contained'
          className={classes.cancel}
        >
          <Typography>Cancel</Typography>
        </Button>
        <Button
          autoFocus
          onClick={onClose}
          variant='contained'
          className={classes.delete}
        >
          <Typography>Delete</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  value: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ConfirmationDialog
