import React, { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'
import api from '../../api'
import {
  DEFAULT_SERVER_ERROR_MESSAGE,
  TEXT_TO_SPEECH_API_BAD_RESPONSE_MESSAGE
} from '../../constants/errorMessages'

const VOICES = Object.freeze({
  'en-US': ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
  'en-GB': ['A', 'B', 'C', 'D', 'F'],
  'fr-FR': ['A', 'B', 'C', 'D', 'E'],
  'ko-KR': ['A', 'B', 'C', 'D'],
  'es-ES': ['A', 'B', 'C', 'D'],
  'pt-PT': ['A', 'B', 'C', 'D'],
  'ru-RU': ['A', 'B', 'C', 'D', 'E'],
  'hu-HU': ['A'],
  'pl-PL': ['A', 'B', 'C', 'D', 'E']
})

const fetchTargetAudio = async (
  language,
  practicePhrase,
  voiceName,
  setErrorMessage
) => {
  return api
    .get('/target-audio', {
      params: {
        language: language,
        practicePhrase: practicePhrase,
        voiceName: language + '-Standard-' + voiceName
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      responseType: 'blob'
    })
    .then(response => response.data)
    .catch(({ response }) => {
      let message = DEFAULT_SERVER_ERROR_MESSAGE
      if (response?.status === 400) {
        message = TEXT_TO_SPEECH_API_BAD_RESPONSE_MESSAGE
      }
      setErrorMessage(message)
    })
}

const NativeAudioPlayer = ({
  language,
  practicePhrase,
  voiceName,
  setVoiceName,
  setAlertMessage,
  nativeAudioUrl,
  setNativeAudioUrl,
  isPlayingNativeAudio,
  setIsPlayingNativeAudio,
  setIsGlowingNative
}) => {
  const [targetAudio, setTargetAudio] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isPlayingNativeAudio) {
      return
    }

    if (!nativeAudioUrl) {
      setAlertMessage(errorMessage)
      setIsPlayingNativeAudio(false)
    }
  }, [isPlayingNativeAudio])

  useEffect(() => {
    if (targetAudio) {
      const nativeAudioUrl = window.URL.createObjectURL(targetAudio)
      setNativeAudioUrl(nativeAudioUrl)
    }
  }, [targetAudio])

  useEffect(() => {
    if (targetAudio) {
      setTargetAudio(null)
      setNativeAudioUrl('')
    }
  }, [practicePhrase, voiceName])

  useEffect(() => {
    if (!practicePhrase || !language || !voiceName) {
      return
    }

    debouncedFetchAudio(language, practicePhrase, voiceName, setErrorMessage)
  }, [practicePhrase, voiceName])

  useEffect(() => {
    if (language) {
      const voicesForLanguage = VOICES[language]
      setVoiceName(voicesForLanguage[0])
    }
  }, [language])

  const getNextVoice = () => {
    const voicesForLanguage = VOICES[language]
    const voiceNameIndex = voicesForLanguage.indexOf(voiceName)

    return voicesForLanguage[(voiceNameIndex + 1) % voicesForLanguage.length]
  }

  const debouncedFetchAudio = useMemo(
    () =>
      debounce(async (language, practicePhrase, voiceName, setAlertMessage) => {
        const audio = await fetchTargetAudio(
          language,
          practicePhrase,
          voiceName,
          setAlertMessage
        )
        if (audio) {
          setTargetAudio(audio)
        }
      }, 250),
    [voiceName]
  )

  const onAudioEnd = () => {
    setIsPlayingNativeAudio(false)
    setIsGlowingNative(false)
    const nextVoice = getNextVoice()
    setVoiceName(nextVoice)
  }

  return (
    <>
      {isPlayingNativeAudio && Boolean(nativeAudioUrl) && (
        <audio
          src={nativeAudioUrl}
          autoPlay
          onEnded={onAudioEnd}
          onPlay={() => setIsGlowingNative(true)}
        />
      )}
    </>
  )
}

export default NativeAudioPlayer
