import React from 'react'
import Timer from 'react-compound-timer'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  recorderIndicatorContainer: {
    display: 'flex',
    height: '2rem',
    width: '4rem',
    margin: '2rem auto 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '50px',
    padding: '0 14px 0 8px',
    backgroundColor: theme.palette.grey[200]
  },

  recorderIcon: {
    color: theme.palette.error.dark,
    marginRight: '0.2rem'
  }
}))

const RecorderIndicator = () => {
  const classes = useStyles()
  return (
    <div
      className={classes.recorderIndicatorContainer}
      aria-label='recorder-indicator'
    >
      <FiberManualRecordIcon
        className={classes.recorderIcon}
        aria-label='indicator-icon'
      />
      <Timer formatValue={value => `${value < 10 ? `0${value}` : value}`}>
        <Timer.Minutes />:
        <Timer.Seconds />
      </Timer>
    </div>
  )
}

export default RecorderIndicator
