import React, { useState } from 'react'
import {
  CardContent,
  TextField,
  Button,
  Fade,
  Typography
} from '@material-ui/core'
import update from 'immutability-helper'
import { makeStyles } from '@material-ui/core/styles'

const HELPER_TEXT = 'Enter a phrase to practice'

const useStyles = makeStyles(theme => ({
  editingCardContent: {
    margin: '0 auto',
    maxWidth: '44rem',
    height: '4rem'
  },
  promptInput: {
    marginTop: '2rem'
  },
  CancelSaveButtonWrapper: {
    marginTop: theme.spacing(7),
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  cancel: {
    minWidth: '5.7rem',
    height: '2.81rem'
  },
  save: {
    minWidth: '5.7rem',
    height: '2.81rem'
  }
}))

const EditMode = ({
  practicePhrase,
  setPracticePhrase,
  setIsEditing,
  prompts,
  setPrompts,
  selectedListName
}) => {
  const [draftPracticePhrase, setDraftPracticePhrase] = useState(practicePhrase)
  const [draftPrompt, setDraftPrompt] = useState('')
  const classes = useStyles()

  const changePracticePhrase = () => {
    setPracticePhrase(draftPracticePhrase)
    setIsEditing(false)
  }

  const updatePrompts = (
    practicePhrase,
    selectedListName,
    draftPrompt,
    prompts
  ) => {
    const selectedListPrompts = prompts[selectedListName] || {}
    if (selectedListPrompts[practicePhrase] === draftPrompt) {
      return
    }

    const updatedSelectedListPrompts = update(selectedListPrompts, {
      $merge: { [practicePhrase]: draftPrompt }
    })
    const updatedPrompts = update(prompts, {
      [selectedListName]: { $set: updatedSelectedListPrompts }
    })
    setPrompts(updatedPrompts)
    localStorage.setItem('prompts', JSON.stringify(updatedPrompts))
  }

  const getPrompt = (prompts, selectedListName, practicePhrase) => {
    if (
      !prompts[selectedListName] ||
      !prompts[selectedListName][practicePhrase]
    ) {
      return ''
    }

    return prompts[selectedListName][practicePhrase]
  }

  return (
    <div data-testid='practice-card-edit-mode'>
      <CardContent className={classes.editingCardContent}>
        <TextField
          type='text'
          label='Phrase To Practice'
          aria-label='practice-phrase'
          variant='outlined'
          size='small'
          fullWidth
          multiline
          rowsMax={3}
          defaultValue={practicePhrase}
          className={classes.editableInputComponent}
          inputProps={{ 'aria-label': 'practice phrase input' }}
          onChange={event => {
            setDraftPracticePhrase(event.target.value.trim())
          }}
          error={draftPracticePhrase === ''}
          helperText={Boolean(!draftPracticePhrase) && HELPER_TEXT}
        />

        <Fade in={Boolean(draftPracticePhrase)} mountOnEnter unmountOnExit>
          <TextField
            type='text'
            label='Custom Prompt (Optional)'
            aria-label='custom prompt'
            variant='outlined'
            size='small'
            defaultValue={getPrompt(prompts, selectedListName, practicePhrase)}
            fullWidth
            className={classes.promptInput}
            inputProps={{ 'aria-label': 'custom prompt input' }}
            onChange={event => {
              setDraftPrompt(event.target.value.trim())
            }}
          />
        </Fade>
      </CardContent>

      <div className={classes.CancelSaveButtonWrapper}>
        <Button
          className={classes.cancel}
          variant='contained'
          onClick={() => setIsEditing(false)}
        >
          <Typography>Cancel</Typography>
        </Button>
        <Fade in={Boolean(draftPracticePhrase)} mountOnEnter unmountOnExit>
          <Button
            className={classes.save}
            variant='contained'
            color='primary'
            onClick={() => {
              updatePrompts(
                practicePhrase,
                selectedListName,
                draftPrompt,
                prompts
              )
              changePracticePhrase()
            }}
          >
            <Typography>Save</Typography>
          </Button>
        </Fade>
      </div>
    </div>
  )
}

export default EditMode
