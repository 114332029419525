import React, { useState } from 'react'
import update from 'immutability-helper'
import { makeStyles } from '@material-ui/core/styles'
import sharedStyles from '../../sharedStyles'
import {
  Card,
  CardContent,
  TextField,
  CardActions,
  Button,
  Typography,
  TextareaAutosize
} from '@material-ui/core'
import { updateUserData } from '../phraseList/phraseList'

const textareaPlaceholderText = [
  'Enter your own phrases here                                                          ',
  'Put them each on a separate line                                                     ',
  'Save the list to begin practicing'
].join('\n')

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto'
  },
  cardContent: {
    margin: '0 auto'
  },
  cancelButton: {
    width: '6.25rem'
  },
  saveButton: {
    width: '6.25rem'
  },
  textArea: {
    width: 'calc(100% - 14px)',
    font: 'inherit',
    fontSize: 'small',
    marginTop: '1rem',
    padding: '6px'
  },
  cardAction: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(2)
    }
  }
}))

const CAPTURE_PHRASE_AND_PROMPT = /^([^()]+)\[(.*)\]$/

const getPhraseAndPrompts = itemStr => {
  const [original, phrase, prompt] = itemStr.match(
    CAPTURE_PHRASE_AND_PROMPT
  ) || [itemStr, itemStr, null]
  if (prompt) {
    return [phrase, prompt]
  }
  return [original]
}

const CreateCustomList = ({
  language,
  setIsCreatingList,
  isEditingList,
  setIsEditingList,
  allCustomLists,
  setAllCustomLists,
  prompts,
  setPrompts,
  selectedListName,
  setSelectedListName,
  selectedPhraseList,
  isSignedIn,
  loggedInUserEmail
}) => {
  const customLists = allCustomLists[language] || {}
  const [draftListTitle, setDraftListTitle] = useState(selectedListName)
  const [draftCustomList, setDraftCustomList] = useState([])

  const classes = useStyles()
  const sharedStyle = sharedStyles()

  const extractPhrasesAndPrompts = draftCustomList => {
    const phrases = []
    const newPrompts = []
    draftCustomList
      .filter(item => item !== '')
      .forEach(item => {
        const [phrase, prompt] = getPhraseAndPrompts(item)
        phrases.push(phrase)
        newPrompts.push(prompt)
      })

    return { phrases: phrases, newPrompts: newPrompts }
  }

  const save = (
    draftListTitle,
    draftCustomList,
    selectedListName,
    isEditingList
  ) => {
    if (draftCustomList.length <= 0) {
      return
    }
    const { phrases, newPrompts } = extractPhrasesAndPrompts(draftCustomList)
    const updatedList = update(customLists, {
      $merge: {
        [draftListTitle]: phrases
      }
    })

    const updateAllCustomList = update(allCustomLists, {
      [language]: { $set: updatedList }
    })
    setAllCustomLists(updateAllCustomList)
    localStorage.setItem('allCustomLists', JSON.stringify(updateAllCustomList))

    if (isEditingList && selectedListName !== draftListTitle) {
      delete updatedList[selectedListName]
      setSelectedListName(draftListTitle)
    }

    const updatedPrompts = getUpdatePrompts(
      phrases,
      newPrompts,
      draftListTitle,
      prompts
    )
    setPrompts(updatedPrompts)
    localStorage.setItem('prompts', JSON.stringify(updatedPrompts))
    setIsCreatingList(false)
    setIsEditingList(false)

    if (isSignedIn) {
      updateUserData(loggedInUserEmail, {
        allCustomLists: JSON.stringify(updateAllCustomList),
        prompts: JSON.stringify(updatedPrompts)
      })
    }
  }

  const handleTextareaChange = e => {
    const newPhrases = e.target.value.trim()

    if (newPhrases === '') {
      setDraftCustomList([])
    } else {
      setDraftCustomList(newPhrases.split('\n'))
    }
  }

  const getUpdatePrompts = (phrases, newPrompts, listName, prompts) => {
    if (phrases.length === 0) {
      return
    }

    const newlyAddedPrompts = {}
    phrases.map((phrase, index) => {
      if (newPrompts[index]) {
        newlyAddedPrompts[phrase] = newPrompts[index]
      }
    })

    const selectedListPrompts = prompts[listName] || {}
    const updatedSelectedListPrompts = update(selectedListPrompts, { $merge: newlyAddedPrompts })
    return update(prompts, {
      [listName]: { $set: updatedSelectedListPrompts }
    })
  }

  const appendPrompt = phrase => {
    const prompt = prompts[selectedListName][phrase]
    if (!prompt) {
      return phrase
    }
    return `${phrase} [${prompt}]`
  }

  const getPhrasesWithPrompts = phraseList => {
    return phraseList.map(appendPrompt)
  }

  const cancel = () => {
    setIsCreatingList(false)
    setIsEditingList(false)
  }

  return (
    <div data-testid='create-custom-list'>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant='body1' align='left' className={sharedStyle.mt1}>
            Enter List Title
          </Typography>
          <TextField
            required
            fullWidth
            id='outlined-basic'
            label='Title'
            variant='outlined'
            size='small'
            className={sharedStyle.mt1}
            defaultValue={isEditingList ? selectedListName : ''}
            inputProps={{ 'aria-label': 'list name input' }}
            onChange={e => setDraftListTitle(e.target.value)}
          />
          <Typography variant='body1' align='left' className={sharedStyle.mt1}>
            Enter Phrases
          </Typography>
          <TextareaAutosize
            type='text'
            aria-label='custom phrases input'
            placeholder={textareaPlaceholderText}
            rowsMin={10}
            defaultValue={
              isEditingList
                ? getPhrasesWithPrompts(selectedPhraseList).join('\r\n')
                : ''
            }
            className={classes.textArea}
            onChange={handleTextareaChange}
          />
        </CardContent>

        <CardActions className={classes.cardAction}>
          <Button
            variant='contained'
            className={classes.cancelButtons}
            onClick={() => cancel()}
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.saveButton}
            onClick={() =>
              save(draftListTitle, draftCustomList, selectedListName, isEditingList)}
          >
            <Typography>Save</Typography>
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default CreateCustomList
