import React, { useEffect, useState } from 'react'
import update from 'immutability-helper'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import arrayMove from 'array-move'
import classNames from 'classnames'
import SuccessCounter from '../successCounter/successCounter'
import ListSelector from '../listSelector/listSelector'
import {
  getPracticePhraseFromLocalStore
} from '../../App'
import { isMatch, isEmptyObject } from '../../helpers'
import api from '../../api'
/* eslint-disable */
import englishCommonPhrases from '!raw-loader!../../assets/languages/en-US/CommonPhrases'
import englishElementalSound from '!raw-loader!../../assets/languages/en-US/ElementalSounds'
import frenchCommonPhrases from '!raw-loader!../../assets/languages/fr-FR/CommonPhrases'
import koreanCommonPhrases from '!raw-loader!../../assets/languages/ko-KR/CommonPhrases'
import spanishCommonPhrases from '!raw-loader!../../assets/languages/es-ES/CommonPhrases'
import portugueseCommonPhrases from '!raw-loader!../../assets/languages/pt-PT/CommonPhrases'
import russianCommonPhrases from '!raw-loader!../../assets/languages/ru-RU/CommonPhrases'
import hungarianCommonPhrases from '!raw-loader!../../assets/languages/hu-HU/CommonPhrases'
import polishCommonPhrases from '!raw-loader!../../assets/languages/pl-PL/CommonPhrases'
/* eslint-enable */
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Fade,
  Button,
  Typography,
  Paper,
  Icon
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import CreateCustomList from '../createCustomList/createCustomList'

const TWELVE_HOURS = 12 * 60 * 60 * 1000

const PHRASE_LISTS = {
  'en-US': {
    'Common Phrases': englishCommonPhrases.trim().split('\n'),
    'Elemental Sounds': englishElementalSound.trim().split('\n')
  },
  'en-GB': {
    'Common Phrases': englishCommonPhrases.trim().split('\n'),
    'Elemental Sounds': englishElementalSound.trim().split('\n')
  },
  'fr-FR': {
    'Common Phrases': frenchCommonPhrases.trim().split('\n')
  },
  'ko-KR': {
    'Common Phrases': koreanCommonPhrases.trim().split('\n')
  },
  'es-ES': {
    'Common Phrases': spanishCommonPhrases.trim().split('\n')
  },
  'pt-PT': {
    'Common Phrases': portugueseCommonPhrases.trim().split('\n')
  },
  'ru-RU': {
    'Common Phrases': russianCommonPhrases.trim().split('\n')
  },
  'hu-HU': {
    'Common Phrases': hungarianCommonPhrases.trim().split('\n')
  },
  'pl-PL': {
    'Common Phrases': polishCommonPhrases.trim().split('\n')
  }
}

const useStyles = makeStyles(theme => ({
  phraseList: {
    marginTop: '8px'
  },
  filledStarIcon: {
    color: theme.palette.warning.main
  },
  dragHandleIcon: {
    verticalAlign: 'middle',
    minWidth: 'unset',
    padding: '9px 16px'
  },
  listSuccessCount: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.success.dark
  },
  listItemText: {
    marginLeft: '1rem'
  },
  iconButton: {
    padding: '10px'
  },
  listItem: {
    width: '26.25rem',
    [theme.breakpoints.down('xs')]: {
      width: '20.25rem'
    }
  },
  editList: {
    marginTop: '1rem',
    height: '2.81rem'
  },
  selectedListNameWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 1rem',
    width: '24.25rem',
    [theme.breakpoints.down('xs')]: {
      width: '18.5rem'
    },
    position: 'relative',
    minHeight: '3.125rem',
    marginBottom: '8px'
  },
  selectedListName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  typeSelectorIcon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)'
  }
}))

const deletePhrase = (
  phrase,
  language,
  selectedListName,
  allCustomLists,
  setAllCustomLists,
  setIsOpenListSelector,
  setIsPreviouslySelectedListAvailable
) => {
  const customLists = allCustomLists[language] || {}
  const selectedList = customLists[selectedListName] || []
  const itemIndex = selectedList.indexOf(phrase)

  const updatedSelectedList = update(customLists, {
    [selectedListName]: { $splice: [[itemIndex, 1]] }
  })

  if (updatedSelectedList[selectedListName].length <= 0) {
    delete updatedSelectedList[selectedListName]
    setIsOpenListSelector(true)
    setIsPreviouslySelectedListAvailable(false)
  }

  setAllCustomLists(
    update(allCustomLists, {
      [language]: { $set: updatedSelectedList }
    })
  )
}

const successCountForPhrase = (practicePhrase, successCounts) => {
  const practicePhraseSuccessCount = successCounts[practicePhrase] || {}
  return practicePhraseSuccessCount.successCount || 0
}

const verifyTranscription = (practicePhrase, transcription, language) => {
  return isMatch(practicePhrase, transcription, language)
}

export const updateUserData = async (userName, userData) => {
  return api.post('/user-data', userData, {
    params: { userName }
  })
}

const getUpdatedAllSuccessCounts = (language, successCounts, allSuccessCounts) => {
  const now = new Date()
  const item = {
    value: successCounts,
    expiry: now.getTime() + TWELVE_HOURS
  }

  return update(allSuccessCounts, {
    [language]: { $set: item }
  })
}

const setAllSuccessCountsInLocalStorage = (allSuccessCounts) => {
  localStorage.setItem('allSuccessCounts', JSON.stringify(allSuccessCounts))
}

const PhraseList = props => {
  const {
    language,
    practicePhrase,
    setPracticePhrase,
    transcription,
    setIsListCompleted,
    successCount,
    setSuccessCount,
    selectedListName,
    setSelectedListName,
    successCounts,
    setSuccessCounts,
    setIsPhraseCompleted,
    prompts,
    setPrompts,
    selectedPhraseList,
    setSelectedPhraseList,
    isOpenListSelector,
    setIsOpenListSelector,
    setIsChoosingLanguage,
    allCustomLists,
    setAllCustomLists,
    isPreviouslySelectedListAvailable,
    setIsPreviouslySelectedListAvailable,
    isSignedIn,
    loggedInUserEmail,
    allSuccessCounts,
    setAllSuccessCounts
  } = props
  const availableLists = PHRASE_LISTS[language]
  const availableListNames = Object.keys(availableLists)
  const [selectedPhrase, setSelectedPhrase] = useState(
    practicePhrase || selectedPhraseList[0]
  )
  const [isCreatingList, setIsCreatingList] = useState(false)
  const customListNames = Object.keys(allCustomLists[language] || {})
  const isSelectedListNameCustom = customListNames.includes(selectedListName)
  const [isEditingList, setIsEditingList] = useState(false)
  const classes = useStyles()

  const wait = async timeout => {
    await new Promise(resolve => setTimeout(resolve, timeout))
  }

  useEffect(() => {
    if (!language) {
      return
    }
    const isPreviouslySelectedListAvailable = [
      ...availableListNames,
      ...customListNames
    ].includes(selectedListName)
    if (!isPreviouslySelectedListAvailable) {
      setIsPreviouslySelectedListAvailable(false)
      setIsOpenListSelector(true)
    }
  }, [language])

  useEffect(() => {
    if (!practicePhrase) {
      return
    }

    if (practicePhrase !== selectedPhrase) {
      setSelectedPhrase('')
    }

    setSuccessCount(successCountForPhrase(practicePhrase, successCounts))
    setSelectedPhrase(practicePhrase)
  }, [practicePhrase])

  useEffect(() => {
    const lastChosenPhrase = getPracticePhraseFromLocalStore(
      language,
      selectedListName
    )
    setSelectedPhrase(lastChosenPhrase || selectedPhraseList[0])
    setPracticePhrase(lastChosenPhrase || selectedPhraseList[0])
    setIsListCompleted(false)
  }, [selectedPhraseList])

  useEffect(() => {
    if (!transcription) {
      return
    }
    let successCount = successCountForPhrase(practicePhrase, successCounts)
    const isSuccess = verifyTranscription(
      practicePhrase,
      transcription,
      language
    )

    if (isSuccess) {
      successCount = Math.min(successCount + 1, 3)
    } else {
      successCount = 0
    }

    updateSuccessCounts(practicePhrase, successCount, isSignedIn, loggedInUserEmail, false)
    if (successCount === 3) {
      wait(2100)
        .then(() => updateSuccessCounts(practicePhrase, successCount, isSignedIn, loggedInUserEmail, true))
        .then(nextPhrase)
    }
  }, [transcription])

  useEffect(() => {
    if (!practicePhrase || isEmptyObject(successCounts)) {
      return
    }
    setIsListCompleted(isMasteredList(selectedPhraseList, successCounts))
  }, [successCounts])

  useEffect(() => {
    if (!practicePhrase) {
      return
    }
    const belongsToSelectedList = selectedPhraseList.includes(practicePhrase)
    const wasPreviouslyCelebrated =
      !isEmptyObject(successCounts) &&
      belongsToSelectedList &&
      isPhrasePreviouslyCelebrated(successCounts)

    if (!wasPreviouslyCelebrated || !belongsToSelectedList) {
      setIsPhraseCompleted(successCount === 3)
    }
  }, [successCount])

  useEffect(() => {
    if (!language || !selectedListName) {
      return
    }

    const customLists = allCustomLists[language] || {}
    const phraseList = availableLists[selectedListName] || customLists[selectedListName] || []
    setSelectedPhraseList(phraseList)
  }, [language, selectedListName, allCustomLists])

  useEffect(() => {
    if (!isOpenListSelector) {
      setIsCreatingList(false)
    }
  }, [isOpenListSelector])

  const isPhrasePreviouslyCelebrated = successCounts => {
    if (!successCounts[practicePhrase]) {
      return false
    }
    const practicePhraseSuccessCount = successCounts[practicePhrase]
    return practicePhraseSuccessCount.isCelebrated
  }

  const isMasteredList = (selectedPhraseList, successCounts) => {
    const masteredPhraseCount = selectedPhraseList.filter(
      phrase =>
        successCounts[phrase] && successCounts[phrase].successCount === 3
    ).length

    const isCelebratedList = selectedPhraseList.every(
      phrase =>
        successCounts[phrase] && successCounts[phrase].isCelebrated
    )

    return (
      masteredPhraseCount &&
      masteredPhraseCount === selectedPhraseList.length &&
      !isCelebratedList
    )
  }

  const nextPhrase = () => {
    const selectedPhraseIndex = selectedPhraseList.indexOf(practicePhrase)
    if (
      selectedPhraseIndex !== -1 &&
      selectedPhraseIndex < selectedPhraseList.length - 1
    ) {
      const newPhrase = selectedPhraseList[selectedPhraseIndex + 1]
      setSelectedPhrase(newPhrase)
      setPracticePhrase(newPhrase)
    }
  }

  const onDrop = params => {
    const sourceIndex = params.source.index
    const destinationIndex = params.destination?.index
    if (destinationIndex) {
      const customLists = allCustomLists[language] || {}
      const selectedList = customLists[selectedListName] || []
      const sortedCustomLists = update(customLists, {
        [selectedListName]: {
          $set: arrayMove(selectedList, sourceIndex, destinationIndex)
        }
      })
      setAllCustomLists(
        update(allCustomLists, {
          [language]: { $set: sortedCustomLists }
        })
      )
    }
  }

  const updateSuccessCounts = (phrase, successCount, isSignedIn, loggedInUserEmail, isCelebrated) => {
    const selectedPhraseIndex = selectedPhraseList.indexOf(phrase)
    if (selectedPhraseIndex !== -1) {
      const updatedSuccessCounts = update(successCounts, {
        $merge: {
          [phrase]: { successCount, isCelebrated }
        }
      })
      setSuccessCounts(updatedSuccessCounts)
      const updateAllSuccessCounts = getUpdatedAllSuccessCounts(language, updatedSuccessCounts, allSuccessCounts)
      setAllSuccessCounts(updateAllSuccessCounts)
      setAllSuccessCountsInLocalStorage(updateAllSuccessCounts)
      if (isSignedIn) {
        updateUserData(loggedInUserEmail, { allSuccessCounts: JSON.stringify(updateAllSuccessCounts) })
      }
    }
  }

  const getPracticePrompt = (practicePhrase) => {
    const selectedListPrompts = prompts[selectedListName] || {}
    return selectedListPrompts[practicePhrase] || practicePhrase
  }

  const editList = () => {
    setIsEditingList(true)
    setIsCreatingList(true)
  }

  return (
    <>
      {availableLists && (
        <>
          <ListSelector
            language={language}
            availableListNames={availableListNames}
            selectedListName={selectedListName}
            setSelectedListName={setSelectedListName}
            isOpenListSelector={isOpenListSelector}
            setIsOpenListSelector={setIsOpenListSelector}
            setIsChoosingLanguage={setIsChoosingLanguage}
            isCreatingList={isCreatingList}
            setIsCreatingList={setIsCreatingList}
            customListNames={customListNames}
            isPreviouslySelectedListAvailable={isPreviouslySelectedListAvailable}
            setIsPreviouslySelectedListAvailable={setIsPreviouslySelectedListAvailable}
            allCustomLists={allCustomLists}
            setAllCustomLists={setAllCustomLists}
          />
          <Collapse
            in={isCreatingList || isEditingList}
            appear={false}
            mountOnEnter
            unmountOnExit
          >
            <CreateCustomList
              language={language}
              setIsCreatingList={setIsCreatingList}
              isEditingList={isEditingList}
              setIsEditingList={setIsEditingList}
              allCustomLists={allCustomLists}
              setAllCustomLists={setAllCustomLists}
              prompts={prompts}
              setPrompts={setPrompts}
              selectedListName={selectedListName}
              setSelectedListName={setSelectedListName}
              selectedPhraseList={selectedPhraseList}
              isSignedIn={isSignedIn}
              loggedInUserEmail={loggedInUserEmail}
            />
          </Collapse>
          <Collapse
            in={
              !isCreatingList && !isEditingList && selectedPhraseList.length > 0
            }
          >
            <DragDropContext
              onDragEnd={params => {
                onDrop(params)
              }}
            >
              <List
                translate='no'
                aria-label='phrase-list'
                className={classes.phraseList}
              >
                <Paper className={classes.selectedListNameWrapper}>
                  <Typography className={classes.selectedListName}>
                    {selectedListName}
                  </Typography>
                  <Fade in={Boolean(!isOpenListSelector)} unmountOnExit>
                    <IconButton
                      className={classes.typeSelectorIcon}
                      aria-label='open list selector'
                      onClick={() => setIsOpenListSelector(!isOpenListSelector)}
                    >
                      <Icon className={classNames('fas fa-exchange-alt')} />
                    </IconButton>
                  </Fade>
                </Paper>
                <Droppable droppableId='droppable-1'>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedPhraseList.map((phrase, index) => (
                        <Draggable
                          key={index}
                          draggableId={'draggable-' + index}
                          index={index}
                          isDragDisabled={!isSelectedListNameCustom}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              key={index}
                              aria-label={phrase}
                              dense
                              button
                              selected={selectedPhrase === phrase}
                              onClick={() => setPracticePhrase(phrase)}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? '0 0 .4rem #666'
                                  : 'none',
                                height: '2.81rem',
                                paddingRight: 15,
                                paddingLeft: 'unset'
                              }}
                              className={classes.listItem}
                            >
                              {isSelectedListNameCustom && (
                                <ListItemIcon
                                  className={classes.dragHandleIcon}
                                  {...provided.dragHandleProps}
                                >
                                  <DragHandleIcon
                                    aria-label={'Drag icon: ' + phrase}
                                  />
                                </ListItemIcon>
                              )}

                              <ListItemText
                                primary={getPracticePrompt(phrase)}
                                className={classes.listItemText}
                              />

                              {successCounts[phrase] && successCounts[phrase].successCount > 0 && (
                                <SuccessCounter
                                  value={
                                    successCounts[phrase].successCount
                                  }
                                  size={35}
                                  toolTipPlacement='top-end'
                                  bgColor='#fafafa'
                                />
                              )}
                              {isSelectedListNameCustom && (
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge='end'
                                    value={phrase}
                                    onClick={() =>
                                      deletePhrase(
                                        phrase, language, selectedListName, allCustomLists, setAllCustomLists, setIsOpenListSelector, setIsPreviouslySelectedListAvailable)}
                                    aria-label={'Delete from custom: ' + phrase}
                                    style={{ padding: 0, marginLeft: 15 }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Fade in={!isEditingList && isSelectedListNameCustom}>
                  <Button
                    className={classes.editList}
                    variant='contained'
                    color='primary'
                    onClick={() => editList()}
                  >
                    <Typography>Edit List</Typography>
                  </Button>
                </Fade>
              </List>
            </DragDropContext>
          </Collapse>
        </>
      )}
    </>
  )
}

export default PhraseList
