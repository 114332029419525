import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  cancelButton: {
    width: '6.25rem'
  }
}))

const stopStream = (stream, setStream) => {
  stream.getTracks().forEach((track) => { track.enabled = false })
  setStream(stream)
}

export const stopAudioContext = (audioContext, stream, setStream) => {
  if (audioContext && audioContext.state === 'running') {
    audioContext
      .suspend()
      .then(() =>
        stopStream(stream, setStream)
      )
      .catch(e => console.log('unable to stop AudioContext', e))

    return audioContext
  }
}
const CancelRecordingButton = props => {
  const {
    recorder,
    setRecorder,
    setIsRecording,
    stream,
    setStream,
    audioContext,
    setAudioContext,
    setStartTime,
    setIsSpeaking
  } = props

  const classes = useStyles()

  const stopRecording = () => {
    recorder.stop()
    recorder.setOnAnalysed(null)
    setIsRecording(false)
    setRecorder(recorder)
    const audioContextSuspended = stopAudioContext(audioContext, stream, setStream)
    setIsSpeaking(false)
    setStartTime('')
    setAudioContext(audioContextSuspended)
  }

  return (
    <>
      <Button
        variant='contained'
        aria-label='cancel-button'
        className={classes.cancelButtons}
        onClick={stopRecording}
      >
        <Typography>Cancel</Typography>
      </Button>
    </>
  )
}

export default CancelRecordingButton
