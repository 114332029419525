import React, { useState } from 'react'
import update from 'immutability-helper'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmationDialog from '../confirmationDialog/confirmationDialog'
import {
  Paper,
  IconButton,
  Card,
  Typography,
  Collapse,
  Fade
} from '@material-ui/core'
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone'
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded'
import DeleteIcon from '@material-ui/icons/Delete'

const HELPER_TEXT = 'Please Select List Type'

const useStyles = makeStyles(theme => ({
  instructionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '3.125rem'
  },
  instruction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  listSelector: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '26rem',
    [theme.breakpoints.down('xs')]: {
      width: '20.5rem'
    },
    position: 'relative'
  },
  typesWrapper: {
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    width: '85%'
  },
  listName: {
    height: '6rem',
    width: '7rem',
    [theme.breakpoints.down('xs')]: {
      width: '6.5rem'
    },
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  deleteListName: {
    height: '25px',
    width: '25px',
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const ListSelector = ({
  language,
  availableListNames,
  selectedListName,
  setSelectedListName,
  isOpenListSelector,
  setIsOpenListSelector,
  isCreatingList,
  setIsCreatingList,
  customListNames,
  isPreviouslySelectedListAvailable,
  setIsPreviouslySelectedListAvailable,
  allCustomLists,
  setAllCustomLists
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [listForDeletion, setListForDeletion] = useState('')
  const classes = useStyles()

  const _setSelectedListName = selectedListName => {
    setSelectedListName(selectedListName)
    setIsOpenListSelector(!isOpenListSelector)
    setIsPreviouslySelectedListAvailable(true)
    localStorage.setItem('selectedListName', selectedListName)
  }

  const deleteCustomList = () => {
    const customLists = allCustomLists[language] || {}
    const clonedCustomLists = { ...customLists }
    delete clonedCustomLists[listForDeletion]

    setIsPreviouslySelectedListAvailable(listForDeletion !== selectedListName)
    setIsOpenListSelector(true)
    setAllCustomLists(
      update(allCustomLists, {
        [language]: { $set: clonedCustomLists }
      })
    )
    closeDialog()
  }

  const closeDialog = () => {
    setIsOpenDialog(false)
  }

  const handleDelete = listName => {
    setListForDeletion(listName)
    setIsOpenDialog(true)
  }

  return (
    <div data-testid='list-selector'>
      <ConfirmationDialog
        keepMounted
        open={isOpenDialog}
        onClose={deleteCustomList}
        onCancel={closeDialog}
        value={listForDeletion}
      />
      <Fade in={!isPreviouslySelectedListAvailable} mountOnEnter unmountOnExit>
        <Paper className={classes.instructionWrapper}>
          <Typography className={classes.instruction}>{HELPER_TEXT}</Typography>
        </Paper>
      </Fade>
      <Collapse
        in={isOpenListSelector}
        appear={false}
        mountOnEnter
        unmountOnExit
      >
        <Card className={classes.listSelector} aria-label='list names'>
          <Fade in={isPreviouslySelectedListAvailable} mountOnEnter>
            <IconButton
              className={classes.closeButton}
              onClick={() => setIsOpenListSelector(!isOpenListSelector)}
            >
              <CancelTwoToneIcon aria-label='close list selector' />
            </IconButton>
          </Fade>
          <div className={classes.typesWrapper}>
            {availableListNames.map(listName => (
              <Paper
                className={classes.listName}
                key={listName}
                aria-label={listName}
                onClick={() => _setSelectedListName(listName)}
              >
                <Typography>{listName}</Typography>
              </Paper>
            ))}
            {customListNames.map(listName => (
              <Paper
                className={classes.listName}
                key={listName}
                aria-label={listName}
              >
                <Typography onClick={() => _setSelectedListName(listName)}>
                  {listName}
                </Typography>
                <IconButton
                  className={classes.deleteListName}
                  aria-label={'delete list ' + listName}
                  onClick={() => handleDelete(listName)}
                >
                  <DeleteIcon />
                </IconButton>
              </Paper>
            ))}
            <Paper
              className={classes.listName}
              aria-label='add list'
              onClick={() => setIsCreatingList(!isCreatingList)}
            >
              <AddCircleOutlineRoundedIcon style={{ width: 'unset' }} />
              <Typography>Add List</Typography>
            </Paper>
          </div>
        </Card>
      </Collapse>
    </div>
  )
}

export default ListSelector
