import api from './api'
import { isEmptyObject } from './helpers'

const getPromptsFromLocalStore = () => {
  const promptsStr = localStorage.getItem('prompts')
  return JSON.parse(promptsStr) || {}
}

export const getAllSuccessCountsFromLocalStorage = () => {
  const successCountsStr = localStorage.getItem('allSuccessCounts')
  return JSON.parse(successCountsStr) || {}
}

export const getUserDataFromDB = async email => {
  const responseData = await api
    .get('/user-data', {
      params: { userName: email }
    })
    .then(response => response.data || {})
    .then(data => data.body || {})
    .catch(error => {
      console.log(error.response.data.error)
      return {}
    })
  return responseData
}

const getAllSuccessCountsDB = userDataFromDB => {
  if (!userDataFromDB.allSuccessCounts) {
    return {}
  }

  return JSON.parse(userDataFromDB.allSuccessCounts) || {}
}

const getCustomListsDB = userDataFromDB => {
  if (!userDataFromDB.allCustomLists) {
    return {}
  }

  return JSON.parse(userDataFromDB.allCustomLists) || {}
}

const getPromptsDB = userDataFromDB => {
  if (!userDataFromDB.prompts) {
    return {}
  }

  return JSON.parse(userDataFromDB.prompts) || {}
}

export const getAllSuccessCounts = (isSignedIn, userDataFromDB) => {
  if (isSignedIn && !isEmptyObject(userDataFromDB)) {
    return getAllSuccessCountsDB(userDataFromDB)
  }
  return getAllSuccessCountsFromLocalStorage()
}

export const getCustomLists = (isSignedIn, userDataFromDB) => {
  if (isSignedIn && !isEmptyObject(userDataFromDB)) {
    return getCustomListsDB(userDataFromDB)
  }
  return JSON.parse(localStorage.getItem('allCustomLists')) || {}
}

export const getPrompts = (isSignedIn, userDataFromDB) => {
  if (isSignedIn && !isEmptyObject(userDataFromDB)) {
    return getPromptsDB(userDataFromDB)
  }
  return getPromptsFromLocalStore()
}
